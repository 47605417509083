/**
 ******************************************************
 ******************************************************
 * {LESS} :: HOME - index :: (upplication, 2016)
 ******************************************************
  *****************************************************
 */

@import "../base/_common.less";
// Slider and main claim top first block
@import "../base/_main-claim.less";
@import "../base/_sliders.less";
// special section blocks
@import "../base/_samples.less";
@import "../base/_multidevices.less";
@import "../base/_timeline.less";
@import "../base/_showcases.less";
@import "../base/_youtube-player.less";
@import "../base/_contact.less";


/* ... TYPED SLIDER PLUGIN ... */

.typed-cursor {
  opacity: 1;
  -webkit-animation: blink 0.7s infinite;
  animation: blink 0.7s infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


/* ... FEATURES (three claims under the main slider) :: Home Section ... */

.features {
  padding: 4em 0 5em 0;
  border-bottom: 1px solid @header-border;

  h3 {
    color: @dark-lilac;
    font-size: 1.6em;
    font-weight: 600;
    line-height: 35px;
    margin-top: 10px;

    strong {
      color: #8c91a5;
      font-weight: 800;
      letter-spacing: 0.015em;
    }
  }
}

@media all and (max-width: 768px) {
  .features {
    padding: 3em 0 1em 0;
  }
}

/* ... (6) ALL YOUR APPS ARE PWA (progressive web app) :: Home Section ... */

#how {
  margin: 2em 0;

  .pwa-text {
    p {
      color: lighten(@darkest-lilac, 5%);
      font-size: 18px;
      font-weight: 400;
      line-height: 30px;

      span {
        color: @main-green;
        font-weight: 600;
      }
      
      em {
        color: @dark-lilac;
      }
      // to center (btn)
      &:last-child {
        margin-top: 35px;
        text-align: center;
      }
    }

    // Responsive Behaviour
    .col[data-aos="fade-left"] {
      // to overwrite styles above in some devices
      @media (min-width: 921px) {
        img {
          float: right;
        }
      }
    }
    // to overwrite "materialize" grid in some devices
    > .col {
      @media (max-width: 920px) {
        width: 100% !important;
        img {
          .centered;
        }
      }
    }
  }
}

/* ... (component) :: THREE CARDS GRID layout .... */

// to resert default styles
.about {
  border-top: none;
  padding-bottom: 0;

  .headTitles {
    margin: 3em 0;

    span {
      padding-right: 0;
    }
  }
}

@media (max-width: 480px) {
  .headTitles,
  h4.headTitles,
  h2.headTitles {
    font-size: 1.5em;

    p {
      font-size: 1em;
    }
  }
}

/* HEIGHT */

@media only screen and (max-height: 667px) {
  .plans-head {
    padding: 0;
  }
}