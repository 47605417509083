/**
 ******************************************************
 ******************************************************
 *   to erase and add in another files
 ******************************************************
  *****************************************************
 */

@import (reference) "_mixins";

@media (max-width:1024px) {

  .domain-Serach-bar-grids {
    width: 60.8%;
  }


  .services-left,
  .services-right {
    padding: 4em 0;
  }

  .features-grid h3 {
    font-size: 1.5em;
  }

  .features:not(.features-upp) {
    padding: 2em 0 2em 0;
  }

  .features-head {
    padding-bottom: 1em;
  }

  .services-left p,
  .services-right p {
    width: 83%;
    line-height: 1.5em;
  }

  .features-grid p {
    margin-top: 0.5em;
  }

  .clients-right ul li {
    width: 20%;
  }

  .clients-right ul li a img {
    width: 100%;
  }
}


/**
 ******************************************************
 ******************************************************
 *                              TABLETS
 ******************************************************
  *****************************************************
 */


@media (max-width:768px) {
  nav {
    margin: 1.4% 0 0 23%;
  }

  .contact-info {
    float: none;
    width: 100%;
    padding: 2% 0 2% 0%;
    border-left: none;
  }

  .logo {
    margin-top: 0;
    z-index: 999;
    position: relative;
  }

  .domain-Serach-bar-grids {
    width: 79.2%;
  }



  .services-left,
  .services-right {
    padding: 2em 0;
  }

  .features-grid h3 {
    font-size: 1.5em;
    margin: 0;
    padding: 0.3em 0;
  }

  .features {
    padding: 2em 0 2em 0;
  }

  .features-head {
    padding-bottom: 1em;
  }

  .services-left p,
  .services-right p {
    width: 83%;
    line-height: 1.5em;
  }

  .features-grid p {
    margin-top: 0.5em;
  }

  .clients-right ul li {
    width: 20%;
  }

  .clients-right ul li a img {
    width: 100%;
  }

  nav {
    margin: 1.4% 0 0 0;
  }

  .services-left img {
    width: 30%;
  }

  .services-left h2 {
    font-size: 1.8em;
    padding: 1em 0 0.5em 0;
  }

  .services-right h3 {
    font-size: 5.5em;
    padding: 0;
    line-height: 0.79em;
  }

  .features-grid {
    margin-bottom: 1.5em;
  }

  .features-grid label {
    width: 16%;
  }

  .clients-left {
    float: none;
    width: 100%;
  }

  .clients-right {
    margin-top: 2em;
    float: none;
    width: 100%;
  }

  .c-testmonial-grid {
    width: 100%;
    margin-right: 0%;
    float: none;
  }

  .clients {
    padding: 2em 0 5em;
  }




  .about-left-grids {
    width: 100%;
    float: none;
  }

  .about-right-grids {
    float: none;
    width: 100%;
    padding: 0em 0 0;
  }

  .header-section h1 {
    font-size: 2.2em;
    margin: 0.8em 0 0em;
  }

  .header-section {
    min-height: 100px;
  }

  .about-grids {
    padding: 0.5em 0 2em 0;
  }

  .footer {
    padding: 1em 0;
  }

  .about-grids {
    padding: 0.5em 0 2em 0;
  }

  .about-left-grids h2 {
    font-size: 1.7em;
  }

  .about-left-grids h3 {
    font-size: 1.5em;
  }

  .about-left-grids h4 {
    font-size: 1.2em;
  }

  .latest-news h2 {
    font-size: 1.7em;
  }

  .pricing-table-grid {
    margin: 0 0 2em 0;
  }

  .pricing-table-grid:hover {
    .scale(1);
  }

  .plans-head p {
    width: 80%;
  }

  .pricing-tables {
    padding: 1em 0 0em;
  }

  .domain-grid-right h2 a {
    font-size: 1em;
  }

  .domain-grid-right p {
    width: 90%;
  }

  .domain-grid {
    padding: 1em 0;
  }

  .domain-grids {
    padding: 0 0 3em 0;
  }

  .contact-bottom-grid-left {
    float: none;
    width: 100%;
    padding: 1em 0 0 0;
  }

  .contact-bottom-grid-right {
    float: none;
    width: 100%;
    padding: 1em 0 0 0;
  }
}

/**
 ******************************************************
 ******************************************************
 *                              MOBILE 680
 ******************************************************
  *****************************************************
 */

@media (max-width:680px) {
  nav a#pull img {
    margin-right: 0;
    position: relative;
    bottom: 20px;
  }
}

/**
 ******************************************************
 ******************************************************
 *                              MOBILE 640
 ******************************************************
  *****************************************************
 */

@media (max-width:640px) {
  nav {
    margin: 1.4% 0 0 23%;
  }

  .contact-info {
    float: none;
    width: 100%;
    padding: 2% 0 2% 0%;
    border-left: none;
  }

  .logo {
    margin-top: 0;
    z-index: 999;
    position: relative;
  }

  .domain-Serach-bar-grids {
    width: 96.2%;
  }


  .services-left,
  .services-right {
    padding: 2em 0;
  }

  .features-grid h3 {
    font-size: 1.5em;
    margin: 0;
    padding: 0.3em 0;
  }

  .features {
    padding: 2em 0 2em 0;
  }

  .features-head {
    padding-bottom: 1em;
  }

  .services-left p,
  .services-right p {
    width: 83%;
    line-height: 1.5em;
  }

  .features-grid p {
    margin-top: 0.5em;
  }

  .clients-right ul li {
    width: 20%;
  }

  .clients-right ul li a img {
    width: 100%;
  }

  nav {
    margin: 1.4% 0 0 0;
  }

  .services-left img {
    width: 30%;
  }

  .services-left h2 {
    font-size: 1.5em;
    padding: 1em 0 0.5em 0;
  }

  .services-right h3 {
    font-size: 5.5em;
    padding: 0;
    line-height: 0.79em;
  }

  .features-grid {
    margin-bottom: 1.5em;
  }

  .features-grid label {
    width: 16%;
  }

  .clients-left {
    float: none;
    width: 100%;
  }

  .clients-right {
    margin-top: 2em;
    float: none;
    width: 100%;
  }

  .c-testmonial-grid {
    width: 100%;
    margin-right: 0%;
    float: none;
  }

  .clients {
    padding: 2em 0 5em;
  }

  .about-left-grids {
    width: 100%;
    float: none;
  }

  .about-right-grids {
    float: none;
    width: 100%;
    padding: 0em 0 0;
  }

  .header-section h1 {
    font-size: 2.2em;
    margin: 0.8em 0 0em;
  }

  .header-section {
    min-height: 100px;
  }

  .about-grids {
    padding: 0.5em 0 2em 0;
  }

  .footer {
    padding: 1em 0;
  }

  .about-grids {
    padding: 0.5em 0 2em 0;
  }

  .about-left-grids h2 {
    font-size: 1.7em;
  }

  .about-left-grids h3 {
    font-size: 1.5em;
  }

  .about-left-grids h4 {
    font-size: 1.2em;
  }

  .latest-news h2 {
    font-size: 1.7em;
  }

  .pricing-table-grid {
    margin: 0 0 2em 0;
  }

  .pricing-table-grid:hover {
    .scale(1);
  }

  .plans-head p {
    width: 80%;
  }

  .domain-grid-right h2 a {
    font-size: 1em;
  }

  .domain-grid-right p {
    width: 90%;
  }

  .domain-grid {
    padding: 1em 0;
  }

  .domain-grids {
    padding: 0 0 3em 0;
  }

  .contact-bottom-grid-left {
    float: none;
    width: 100%;
    padding: 1em 0 0 0;
  }

  .contact-bottom-grid-right {
    float: none;
    width: 100%;
    padding: 1em 0 0 0;
  }

  .services-right h2 {
    font-size: 1.5em;
  }

  .features-head h3 {
    font-size: 2em;
  }

  a.s-btn {
    font-size: 1.2em;
    margin-top: 1.2em;
  }

  .contact-grids {
    padding: 1.5em 0 4em 0;
  }
}

/**
 ******************************************************
 ******************************************************
 *                              MOBILE 607
 ******************************************************
  *****************************************************
 */

@media (max-width:607px) {
  nav a#pull img {
    margin-right: 0;
    position: relative;
    bottom: 15px;
  }
}

/**
 ******************************************************
 ******************************************************
 *                              MOBILE 480
 ******************************************************
  *****************************************************
 */

@media (max-width:480px) {
  nav {
    margin: 1.4% 0 0 23%;
  }

  .contact-info {
    float: none;
    width: 100%;
    padding: 2% 0 2% 0%;
    border-left: none;
  }

  .logo {
    margin-top: 0;
    z-index: 999;
    position: relative;
  }

  .domain-Serach-bar-grids {
    width: 96.2%;
    border: 8px solid rgba(255, 255, 255, 0.5);
  }


  .services-left,
  .services-right {
    padding: 2em 0;
  }

  .services-left p,
  .services-right p {
    display: none;
  }

  .features-grid h3 {
    font-size: 1.5em;
    margin: 0;
    padding: 0.3em 0;
  }

  .features {
    padding: 1em 0 1em 0;
  }

  .features-head {
    padding-bottom: 1em;
  }

  .services-left p,
  .services-right p {
    width: 83%;
    line-height: 1.5em;
  }

  .features-grid p {
    margin-top: 0.5em;
  }

  .clients-right ul li {
    width: 20%;
  }

  .clients-right ul li a img {
    width: 100%;
  }

  nav {
    margin: 1.4% 0 0 0;
  }

  .services-left img {
    width: 24%;
  }

  .services-left h2 {
    font-size: 1.3em;
    padding: 1em 0 0.5em 0;
  }

  .services-right h3 {
    font-size: 4em;
    padding: 0;
    line-height: 0.79em;
  }

  .features-grid {
    margin-bottom: 1em;
  }

  .features-grid label {
    width: 16%;
    margin-top: 1em;
  }

  .clients-left {
    float: none;
    width: 100%;
  }

  .clients-right {
    margin-top: 2em;
    float: none;
    width: 100%;
  }

  .c-testmonial-grid {
    width: 100%;
    margin-right: 0%;
    float: none;
  }

  .clients {
    padding: 2em 0 5em;
  }

  .about-left-grids {
    width: 100%;
    float: none;
  }

  .about-right-grids {
    float: none;
    width: 100%;
    padding: 0em 0 0;
  }

  .header-section h1 {
    font-size: 1.8em;
    margin: 0.6em 0 0em;
  }

  .header-section {
    min-height: 65px;
  }

  .about-grids {
    padding: 0.5em 0 2em 0;
  }

  .footer {
    padding: 1em 0;
  }

  .about-grids {
    padding: 0.5em 0 2em 0;
  }

  .about-left-grids h2 {
    font-size: 1.6em;
    margin: 0.5em 0 0.3em;
  }

  .about-left-grids h3 {
    font-size: 1.3em;
  }

  .about-left-grids h4 {
    font-size: 1.2em;
  }

  .latest-news h2 {
    font-size: 1.1em;
  }

  .pricing-table-grid {
    margin: 0 0 2em 0;
  }

  .pricing-table-grid:hover {
    .scale(1);
  }

  .plans-head p {
    width: 90%;
  }

  .pricing-tables {
    padding: 1em 0 0em;
  }

  .domain-grid-right h2 a {
    font-size: 0.8em;
  }

  .domain-grid-right p {
    width: 100%;
    height: 84px;
    overflow: hidden;
  }

  .domain-grid {
    padding: 1em 0;
  }

  .domain-grids {
    padding: 0 0 2em 0;
  }

  .contact-bottom-grid-left {
    float: none;
    width: 100%;
    padding: 1em 0 0 0;
  }

  .contact-bottom-grid-right {
    float: none;
    width: 100%;
    padding: 1em 0 0 0;
  }

  .services-right h2 {
    font-size: 1.4em;
    padding: 1em 0 0.62em 0;
  }

  .features-head h3 {
    font-size: 1.8em;
  }

  a.s-btn {
    font-size: 1em;
    margin-top: 0.4em;
    padding: 0.5em 1.4em;
  }

  .contact-grids {
    padding: 1.5em 0 4em 0;
  }

  .domain-Serach-bar-left {
    width: 42.11%;
  }

  .features-head span {
    font-size: 0.875em;
  }



  .latest-news-grid h3 a {
    font-size: 0.62em;
  }

  .plans-head h2 {
    font-size: 1.6em;
  }

  .plans-head {
    padding: 0 0 1.2em 0;
  }

  .features-grid-right {
    width: 64%;
  }

  .our-features {
    padding: 0 0 2em 0;
  }

  .features-grid-right h4 {
    font-size: 1.3em;
  }

  .our-features-head h2 {
    font-size: 1.6em;
  }

  .our-features-head p {
    width: 90%;
  }

  .our-features-head {
    padding: 0 0 1em 0;
  }

  .hosting-grid h2 {
    font-size: 8em;
  }

  .hosting-grid p {
    font-size: 1.5em;
  }

  .hosting-grid {
    padding: 1em 0;
    min-height: 309px;
  }

  .text-fild {
    width: 100%;
    margin-right: 0%;
    float: none;
    margin-bottom: 1em;
  }

  .copy-right {
    margin-top: 3em;
  }

  .contact-map iframe {
    min-height: 150px;
  }

  .text-fild input[type="text"],
  .msg-fild input[type="text"],
  .message-fild textarea {
    padding: 0.6em;
  }

  .message-fild textarea {
    min-height: 180px;
  }
}

/**
 ******************************************************
 ******************************************************
 *                              MOBILE 320
 ******************************************************
  *****************************************************
 */

@media (max-width:320px) {
  nav {
    margin: 1.4% 0 0 23%;
  }

  .contact-info {
    float: none;
    width: 100%;
    padding: 5% 0 2% 0%;
    border-left: none;
  }

  .logo {
    margin-top: 0.2em;
    z-index: 999;
    position: relative;
  }

  .domain-Serach-bar-grids {
    width: 96.2%;
    border: 8px solid rgba(255, 255, 255, 0.5);
  }


  .services-left,
  .services-right {
    padding: 2em 0;
  }

  .services-left p,
  .services-right p {
    display: none;
  }

  .features-grid h3 {
    font-size: 1.2em;
    margin: 0;
    padding: 0.3em 0;
  }

  .features {
    padding: 1em 0 1em 0;
  }

  .features-head {
    padding-bottom: 1em;
  }

  .services-left p,
  .services-right p {
    width: 83%;
    line-height: 1.5em;
  }

  .features-grid p {
    margin-top: 0.5em;
    font-size: 0.875em;
  }

  .clients-right ul li {
    width: 49%;
    display: inline-block;
    margin-right: 0;
    margin-bottom: 0.5em;
  }

  .clients-right ul li a img {
    width: 100%;
  }

  nav {
    margin: 1.4% 0 0 0;
  }

  .services-left img {
    width: 24%;
  }

  .services-left h2 {
    font-size: 1.2em;
    padding: 1em 0 0.5em 0;
  }

  .services-right h3 {
    font-size: 2.5em;
    padding: 0;
    line-height: 0.89em;
  }

  .features-grid {
    margin-bottom: 1em;
  }

  .features-grid label {
    width: 16%;
    margin-top: 1em;
  }

  .clients-left {
    float: none;
    width: 100%;
  }

  .clients-right {
    margin-top: 1em;
    float: none;
    width: 100%;
  }

  .c-testmonial-grid {
    width: 100%;
    margin-right: 0%;
    float: none;
  }

  .clients {
    padding: 1em 0 4em;
  }


  .about-left-grids {
    width: 100%;
    float: none;
  }

  .about-right-grids {
    float: none;
    width: 100%;
    padding: 0em 0 0;
  }

  .header-section h1 {
    font-size: 1.3em;
    margin: 0.6em 0 0em;
  }

  .header-section {
    min-height: 47px;
  }

  .about-grids {
    padding: 0.5em 0 2em 0;
  }

  .footer {
    padding: 1em 0;
    min-height: 535px;
  }

  .about-grids {
    padding: 0.5em 0 2em 0;
  }

  .about-left-grids h2 {
    font-size: 1.2em;
    margin: 0.3em 0 0.2em;
  }

  .about-left-grids h3 {
    font-size: 1em;
  }

  .about-left-grids h4 {
    font-size: 0.9em;
  }

  .latest-news h2 {
    font-size: 1.1em;
  }

  .pricing-table-grid {
    margin: 0 0 2em 0;
  }

  .pricing-table-grid:hover {
    .scale(1);
  }

  .plans-head p {
    width: 100%;
    font-size: 0.875em;
    height: 44px;
    overflow: hidden;
  }

  .pricing-tables {
    padding: 1em 0 0em;
  }

  .domain-grid-right h2 a {
    font-size: 0.6em;
  }

  .domain-grid-right p {
    width: 100%;
    height: 84px;
    overflow: hidden;
  }

  .domain-grid {
    padding: 1em 0;
  }

  .domain-grids {
    padding: 0 0 2em 0;
  }

  .contact-bottom-grid-left {
    float: none;
    width: 100%;
    padding: 1em 0 0 0;
  }

  .contact-bottom-grid-right {
    float: none;
    width: 100%;
    padding: 1em 0 0 0;
  }

  .services-right h2 {
    font-size: 1.2em;
    padding: 1em 0 0.62em 0;
  }

  .features-head h3 {
    font-size: 1.5em;
  }

  a.s-btn {
    font-size: 1em;
    margin-top: 0.4em;
    padding: 0.5em 1.4em;
  }

  .contact-grids {
    padding: 1em 0 3em 0;
  }

  .domain-Serach-bar-left {
    width: 100%;
  }

  .features-head span {
    font-size: 0.875em;
  }


  .latest-news-grid h3 a {
    font-size: 0.62em;
  }

  .plans-head h2 {
    font-size: 1.2em;
  }

  .plans-head {
    padding: 0 0 1.2em 0;
  }

  .features-grid-right {
    width: 64%;
  }

  .our-features {
    padding: 0 0 2em 0;
  }

  .features-grid-right h4 {
    font-size: 1em;
  }

  .our-features-head h2 {
    font-size: 0.9em;
  }

  .our-features-head p {
    width: 100%;
    font-size: 0.875em;
    height: 41px;
    overflow: hidden;
  }

  .our-features-head {
    padding: 0 0 1em 0;
  }

  .hosting-grid h2 {
    font-size: 7em;
  }

  .hosting-grid p {
    font-size: 1.2em;
  }

  .hosting-grid {
    padding: 0em 0;
    min-height: 218px;
  }

  .text-fild {
    width: 100%;
    margin-right: 0%;
    float: none;
    margin-bottom: 1em;
  }

  .copy-right {
    margin-top: 3em;
  }

  .contact-map iframe {
    min-height: 150px;
  }

  .text-fild input[type="text"],
  .msg-fild input[type="text"],
  .message-fild textarea {
    padding: 0.6em;
    -webkit-appearance: none;
  }

  .message-fild textarea {
    min-height: 180px;
  }

  .contact-info p {
    font-size: 0.68em;
  }

  a.chat {
    padding: 0.5em 0em;
    display: block;
    text-align: center;
  }

  .contact-info-right h3 {
    padding: 0.4em 0;
    display: block;
    font-size: 1.3em;
  }

  .contact-info-left {
    margin: 0;
    padding: 0;
  }

  .domain-Serach-bar-center {
    padding: 0.5em 1em;
    width: 38.4%;
  }

  .clients-left h3 {
    font-size: 1.5em;
  }

  .clients-left p {
    font-size: 0.875em;
  }

  .c-testmonials {
    padding-top: 1.2em;
  }

  .c-testmonial-grid-info a {
    font-size: 1em;
  }

  .map-point {
    position: absolute;
    bottom: 60%;
    right: 30%;
  }

  .map-point a {
    font-size: 0.6em;
  }

  a.to-top {
    font-size: 0.875em;
    margin-top: 2em;
  }

  .footer-right ul.footer-nav li a {
    font-size: 0.9em;
  }

  .footer-right h3 {
    font-size: 1.2em;
  }

  .latest-news-grid span {
    font-size: 0.875em;
    line-height: 1.7em;
  }

  .latest-news-grid p {
    line-height: 1.5em;
    font-size: 0.875em;
  }

  .pricing-table-grid h3 {
    padding: 0.7em 0;
    font-size: 1.3em;
  }

  .pricing-table-grid ul li a,
  .pricing-table-grid ul li span {
    font-size: 0.875em;
  }

  a.order-btn {
    color: #202242;
    padding: 1em 0;
    font-size: 1em;
  }

  .h-features-grid {
    padding: 0.8em 0;
  }

  .features-grid-right span {
    font-size: 0.8em;
  }

  .features-grid-left {
    float: none;
    width: 100%;
  }

  .features-grid-right {
    margin-top: 0.8em;
    width: 100%;
  }

  .features-grid-right p {
    font-size: 0.875em;
    margin: 0.5em 0;
  }

  .domain-grid-right span {
    margin: 0 auto 0em auto;
    display: block;
    height: 22px;
    overflow: hidden;
  }

  .domain-grid-left img {
    width: 40%;
  }

  .contact-bottom-grid-left p:nth-child(2) {
    display: none;
  }

  .contact-bottom-grid-right input[type="submit"] {
    padding: 0.6em 1.8em;
  }

  .top-nav ul li a {
    padding: 0.2em 0em;
    font-size: 0.875em;
  }

  .domain-Serach-bar-left input[type="text"] {
    padding: 0.8em 1em 0.8em 1em;
    -webkit-appearance: none;
  }

  .domain-Serach-bar-center {
    height: 46px;
  }

  .domain-Serach-bar-right input[type="submit"] {
    -webkit-appearance: none;
    padding: 0.75em 0.712em;
  }

  .fleft {
    height: 35px;
  }
}









/* ... UPP CUSTOM MEDIA QUERIES (Responsive Design) ... */

@media only screen and (min-width: 993px) {
  .container {
    width: 92%;
  }
}

@media only screen and (max-width: 1280px) {
  .container {
    width: 100%;
  }
}


@media only screen and (max-width: 1090px) {
  .top-nav ul li a span.bahasa-header {
    display: none;
  }
}

@media only screen and (max-width: 1050px) {
  nav {
    margin-top: 7px;
  }
}


@media only screen and (max-width: 940px) {

  .top-nav ul li a {
    padding: 0.2em .8em;
    font-size: .85em;
  }

  .top-nav ul li a span {
    display: none;
  }
}

@media only screen and (max-width: 920px) {


  .top-nav ul li a {
    padding: 0.2em .7em;
  }
}

@media only screen and (max-width: 895px) {

}

@media only screen and (max-width: 875px) {
  .contact-info {
    display: none;
  }

  nav {
    float: inherit;
  }
}

@media only screen and (max-width: 820px) {
  nav ul {
    float: right;
  }

  .top-nav ul li a {
    padding: 0.2em .8em;
    font-size: .85em;
  }
}



/* ... HEADERS :: TITLE SECTIONS ...................................................... */

@media only screen and (max-width: 450px) {
  h2.headTitles,
  h4 {
    font-size: 2rem;
  }
}



/* ... TIMELINE STEPS ...................................................... */

@media only screen and (max-width: 1199px) {
  .app-ready h4 {
    bottom: 98px;
  }
}

@media only screen and (max-width: 767px) {
  .app-ready {
    margin-left: 13% !important;
    margin-top: 1.5% !important;
    width: 50% !important;
  }

  .timeline .timeline-heading h4 {
    font-size: 1.8em;
  }
}

@media only screen and (max-width: 540px) {

  .app-ready {
    margin-left: 13% !important;
    margin-top: 1.5% !important;
    width: 50% !important;
  }

  .timeline .timeline-heading h4 {
    font-size: 1.6em;
  }

  .timeline .timeline-heading span {
    padding: 4px 10px;
    font-size: 17px;
    bottom: 3px;
  }

  .app-ready h4 {
    font-weight: 600;
    font-size: 25px;
    bottom: 100px;
  }
}

@media only screen and (max-width: 505px) {
  .app-ready {
    margin-left: 14% !important;
  }

  .timeline .timeline-body>p {
    font-size: 17px;
  }

  .timeline .timeline-heading h4 {
    font-size: 1.5em;
  }
}

@media only screen and (max-width: 470px) {
  .app-ready {
    margin-left: 15% !important;
    width: 70% !important;
  }

  .timeline .timeline-heading h4 {
    font-size: 1.4em;
  }

  .timeline .timeline-heading span {
    padding: 3px 9px;
    font-size: 16px;
    bottom: 2px;
  }
}

@media only screen and (max-width: 450px) {
  .app-ready {
    margin-left: 16% !important;
  }

  .timeline .timeline-heading h4 {
    font-size: 1.2em;
  }

  .timeline .timeline-heading span {
    padding: 3px 9px;
    font-size: 16px;
    bottom: 1px;
  }
}

@media only screen and (max-width: 420px) {
  .app-ready {
    margin-left: 18% !important;
    width: 80% !important;
  }
}

@media only screen and (max-width: 390px) {
  .app-ready {
    margin-left: 19% !important;
  }
}

@media only screen and (max-width: 370px) {
  .app-ready {
    margin-left: 20% !important;
  }
}

@media only screen and (max-width: 350px) {
  .app-ready {
    margin-left: 21% !important;
  }
}

@media only screen and (max-width: 330px) {
  .app-ready {
    margin-left: 22% !important;
  }
}

/* ... PRICING LIST BOXES ...................................................... */

@media only screen and (max-width: 1150px) {
  #mensual,
  #anual {
    margin-top: 0;
  }

  #mensual .mobilePrices,
  #anual .mobilePrices {
    width: 33% !important;
    padding-top: 30px;
  }

  .best-seller {
    top: 34px;
  }

  .priceAdvise img {
    top: 33px;
  }
}

@media only screen and (max-width: 920px) {
  #mensual,
  #anual {
    margin-top: 30px;
  }

  #mensual .mobilePrices,
  #anual .mobilePrices {
    width: 50% !important;
    padding-top: 0;
  }

  .best-seller {
    top: 5px;
  }

  .priceAdvise img {
    top: 3px;
  }
}


@media only screen and (max-width: 694px) {
  #anual .alert span.fa-stack i.fa-ban {
    bottom: 63px;
  }
}

@media only screen and (max-width: 680px) {
  .alert-info {
    padding: 10px 20px;
  }
}

@media only screen and (max-width: 640px) {
  #mensual .mobilePrices,
  #anual .mobilePrices {
    width: 60% !important;
    float: none;
    position: relative;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  #pricing .tax p {
    text-align: center;
  }
}

@media only screen and (max-width: 540px) {
  #mensual .mobilePrices,
  #anual .mobilePrices {
    width: 70% !important;
  }
}

@media only screen and (max-width: 440px) {
  #mensual .mobilePrices,
  #anual .mobilePrices {
    width: 80% !important;
  }
}

@media only screen and (max-width: 380px) {
  #mensual .mobilePrices,
  #anual .mobilePrices {
    width: 90% !important;
  }
}

@media only screen and (max-width: 340px) {
  #mensual .mobilePrices,
  #anual .mobilePrices {
    width: 100% !important;
  }
}

/* ... TESTIMONIAL ...................................................... */

@media only screen and (max-width: 1050px) {
  .clients {
    padding: 0;
  }

  .c-testmonial-grid {
    width: 100%;
    margin-bottom: 6em;
  }
}

/* HEIGHT */

@media only screen and (max-height: 667px) {
  .c-testmonials .marBot100 {
    margin-bottom: 50px;
  }

  .c-testmonials h4 {
    margin: 0;
  }
}

/* ... CREA TU APP (footer) ...................................................... */



/* ... SERVICIO ATENCI�N AL CLIENTE (footer) ...................................................... */



/* ... FOOTER ...................................................... */







/* ... LEGAL ADVISE & ABOUT US ...................................................... */

@media only screen and (max-width: 1080px) {
  .about-left-grids {
    padding: 0em .7em 2em 1em;
  }

  .about-left-grids,
  .about-right-grids {
    width: 100%;
  }
}

@media only screen and (max-width: 567px) {
  .about-left-grids {
    padding: 0;
  }
}

/* ... HELP CENTER ................................................. */


@media only screen and (max-width: 850px) {
  #help-center .help-center-grid {
    width: 100%;
  }
}


/* ... MAIN MENU TOP NAV ...................................................... */

@media only screen and (max-width: 768px) {
  .top-nav ul li.only-show-small-devises {
    display: inline-block;
  }

  .top-nav ul li.only-show-small-devises.phone-kerning {
    letter-spacing: 0.04em;
    font-family: "Lato", sans-serif;
  }

  nav ul.top-nav {
    margin-top: -1px;
  }

  nav a#pull img {
    margin-right: 0;
    position: relative;
    bottom: 25px;
  }
}

@media only screen and (min-width: 769px) {
  nav a#pull img {
    margin-right: 3% !important;
    position: relative !important;
    bottom: 10px !important;
  }
}

/* ... LOGIN AREA  ...................................................... */

@media only screen and (max-height: 667px) {
  .popup .popup-container-login {
    margin-top: 110px;
  }

  a.green.backgrounded {
    display: inline-block;
  }

  .popup .popup-container-login header:after {
    left: 48%;
  }
}

@media only screen and (max-height: 375px) {
  .popup .popup-container-login {
    width: 95%;
    max-width: 550px;
    margin-top: 10px;
  }

  .info-box.inbox {
    padding-top: 0;
  }
}

/* ... MOBILE HEADER  ...................................................... */

@media only screen and (max-width: 540px) {
  nav a#pull img {
    margin-right: 0;
    position: relative;
    bottom: 20px;
  }
}

@media only screen and (max-width: 320px) {
  nav a#pull img {
    margin-right: 10px;
    position: relative;
    bottom: 8px;
  }
}

/* ... MOBILE "TRY IT" BUTTON (HOME) ...................................................... */

@media only screen and (min-width: 767px) {
  .register-form-mobile {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .register-form {
    display: none;
  }

  .skew-right {
    -webkit-transform: skew(0deg, -5deg);
    -ms-transform: skew(0deg, -5deg);
    transform: skew(0deg, -5deg);
  }

  /* "TRY IT" BUTTON for MOBILE & TABLETS */

  .register-form-mobile {
    display: block;
    z-index: 100;
    bottom: 45px;
    margin: 10px 0 0 0;
  }

  .register-form-mobile a {
    display: block;
    width: 100%;
    text-align: center;
    font-family: 'Raleway', sans-serif;
    text-transform: uppercase;
    font-weight: 600;
    background-color: #00c86b;
    color: #fff;
    padding: .8em .5em;
    font-size: 1.3em;
    letter-spacing: .03em;
  }

  .register-form-mobile a:hover {
    background-color: #00e386;
  }

  .register-form-mobile a i {
    margin-right: 10px;
  }
}


/* ... HOME INTRO SLIDER BOTTOM SKEW for big screems .................... */

@media only screen and (min-width: 1750px) {
  .dippah.skew-right {
    -webkit-transform: skew(0deg, -2.5deg);
    -ms-transform: skew(0deg, -2.5deg);
    transform: skew(0deg, -2.5deg);
  }
}

/* ... PRICE PAGE ...................................................... */

@media only screen and (max-width: 768px) {
  .pricing-tables.pricing-tables-mobile {
    padding: 3em 0 0em !important;
  }

  #pricing .incluye {
    padding-right: 0;
    padding-left: 0;
  }

  #pricing .incluye ul li span:last-child {
    display: block;
    padding-left: 45px;
    padding-bottom: 8px;
  }
}