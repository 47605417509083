/**
 **********************************************************************
 * (component) :: FULL WIDTH IMAGE BLOCK :: (upplication, 2016)
 **********************************************************************
 */

/* ... "Multidevices Apps" Section :: Home / Telef�nica views ... */

#multidevices {
  background-color: @white;

  .multidevices-bg {
    width: 100%;
    height: auto;
  }
}