/**
 ******************************************************
 ******************************************************
 * {LESS} :: MIXINS :: (upplication, 2016)
 ******************************************************
  *****************************************************
 */

/**
 **********************************
 * FONTS                     *
 **********************************
 */

.font-family-main {
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
}

.font-family-header {
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
}

// Font size for mobile devises ( > 480px)
.font-mobile {
  font-size: 16px;
  line-height: 25px;
}


/**
 **********************************
 * ANIMATIONS  &  TRASNSFORMS     *
 **********************************
 */

// common TRANSITION animation by default
.anim(@type, @time, @anim) {
  -webkit-transition: @type @time @anim;
  -moz-transition: @type @time @anim;
  -o-transition: @type @time @anim;
  transition: @type @time @anim;
}

// specific class in a few .jade files
.animate-hover {
  .anim(all, 150ms, ease-in);
}

// common MOVEMENT animation by default
.animate(@type, @time, @anim) {
  -webkit-animation: @type @time @anim;
  -moz-animation: @type @time @anim;
  -o-animation: @type @time @anim;
  animation: @type @time @anim;
}

// INFINITE ROTATE LOOP animation
.animation-infinite(@time) {
  -webkit-animation: upp-spin @time infinite linear;
  animation: upp-spin @time infinite linear;
  .blur(0);
  -webkit-transform: translateZ(0) scale(1.0, 1.0);
  transform: translateZ(0) scale(1.0, 1.0);
  display: inline-block;
}

// Infinite SPIN LOOP (icon-ccw) animation
@keyframes anim-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
@-webkit-keyframes anim-spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
  }
}

// SMOOTH FLIGHT (icon-rocket) up and down animation
@keyframes smooth-flight {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-8px);
  }
  75% {
    transform: translateY(8px);
  }
  100% {
    transform: translateY(0);
  }
}

@-webkit-keyframes smooth-flight {
  0% {
    -webkit-transform: translateY(0);
  }
  25% {
    -webkit-transform: translateY(-8px);
  }
  75% {
    -webkit-transform: translateY(8px);
  }
  100% {
    -webkit-transform: translateY(0);
  }
}

@-moz-keyframes smooth-flight {
  0% {
    -moz-transform: translateY(0);
  }
  25% {
    -moz-transform: translateY(-8px);
  }
  75% {
    -moz-transform: translateY(8px);
  }
  100% {
    -moz-transform: translateY(0);
  }
}

// SMOOTH FLIGHT (icon-rocket) left and right opacity animation
@keyframes in-out-move {
  0% {
    transform: translateX(-50%);
    opacity: 0;
  }
  50% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateX(50%);
    opacity: 0;
  }
}

@-webkit-keyframes in-out-move {
  0% {
    -webkit-transform: translateX(-50%);
    opacity: 0;
  }
  50% {
    -webkit-transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(50%);
    opacity: 0;
  }
}

@-moz-keyframes in-out-move {
  0% {
    -moz-transform: translateX(-50%);
    opacity: 0;
  }
  50% {
    -moz-transform: translateY(0);
    opacity: 1;
  }
  100% {
    -moz-transform: translateX(50%);
    opacity: 0;
  }
}


/* ... ROTATES ... */

// to apply Rotation
.rotate(@deg) {
  -webkit-transform: rotate(@deg);
  -moz-transform: rotate(@deg);
  -ms-transform: rotate(@deg);
  -o-transform: rotate(@deg);
  transform: rotate(@deg);
}

/* ... MIRROR ... */

// this mixin is like rotate an element 180 degrees (".rotate-180;")
.mirror {
  -webkit-transform: scale(-1, 1);
  -moz-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.scale(@scale) {
  -webkit-transform: scale(@scale);
  -moz-transform: scale(@scale);
  -ms-transform: scale(@scale);
  -o-transform: scale(@scale);
  transform: scale(@scale);
}

/**
 **********************************
 * OTHERS                         *
 **********************************
 */

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Border Radius
.border-radius(@border) {
  -webkit-border-radius: @border;
  border-radius: @border;
}

// ICONS to restart default values
// mixin
.icon-default {
  display: inline-block;
  font-size: 1rem;
  height: 0;
  line-height: 0;
}

// to apply FLEXBOX layout with some dafault values
.flex(@wrap: wrap, @content: center) {
  display: flex;
  flex-direction: row;
  flex-wrap: @wrap;
  justify-content: @content;
  align-items: center;
  align-content: center;
}

/* ... to CENTER Blocks ... */

.centered {
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}

/* ... to CENTER Images ... */

.centerImg img,
.center-img img {
  .centered;
}

/* ... to add BOX SHADOW ... */

.box-shadow {
  -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,.16),0 2px 10px 0 rgba(0,0,0,.12);
  -moz-box-shadow: 0 2px 5px 0 rgba(0,0,0,.16),0 2px 10px 0 rgba(0,0,0,.12);
  -ms-box-shadow: 0 2px 5px 0 rgba(0,0,0,.16),0 2px 10px 0 rgba(0,0,0,.12);
  -o-box-shadow: 0 2px 5px 0 rgba(0,0,0,.16),0 2px 10px 0 rgba(0,0,0,.12);
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.16),0 2px 10px 0 rgba(0,0,0,.12);
}

/* ... LOADER ............................. */

// OPACITY animation
.anim-info-opacity(@opacity) {
  -webkit-animation: opacity-in @opacity ease-in-out;
  animation: opacity-in @opacity ease-in-out;
}
@-webkit-keyframes opacity-in {
  0% {  opacity: 0;  }
  50% {  opacity: .1;  }
  70% {  opacity: .3;  }
  100% {  opacity: 1;  }
}
@keyframes opacity-in {
  0% {  opacity: 0;  }
  50% {  opacity: .1;  }
  70% {  opacity: .3;  }
  100% {  opacity: 1;  }
}

// to Center Block just in the middle
.full-center-block(@position: 50%, @translate: -50%) {
  position: absolute;
  left: @position;
  top: @position;
  -webkit-transform: translate(@translate, @translate);
  transform: translate(@translate, @translate);
  z-index: 1;
}

// main Text mixin with default values
.main-text(@size: 15px, @weight: 400) {
  font-family: "Lato", sans-serif;
  font-size: @size;
  letter-spacing: .02em;
  font-weight: @weight;
}

// Blur effect
.blur(@blur) {
  -webkit-filter: blur(@blur);
  filter: blur(@blur);
}



