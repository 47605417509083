/**
******************************************************
******************************************************
* {LESS} :: ELEMENTS (Bootstrap) :: (upplication, 2016)
******************************************************
*****************************************************
*/

/* ... BOOTSTRAP styles added cause not exist in Matelialize ... */

.clearfix:before,
.clearfix:after,
.container:before,
.container:after,
.container-fluid:before,
.container-fluid:after,
.row:before,
.row:after,
.form-horizontal
.form-group:before,
.form-horizontal
.form-group:after,
.btn-toolbar:before,
.btn-toolbar:after,
.btn-group-vertical>.btn-group:before,
.btn-group-vertical>.btn-group:after,
.nav:before,
.nav:after,
.navbar:before,
.navbar:after,
.navbar-header:before,
.navbar-header:after,
.navbar-collapse:before,
.navbar-collapse:after,
.pager:before,
.pager:after,
.panel-body:before,
.panel-body:after,
.modal-footer:before,
.modal-footer:after {
  content: " ";
  display: table;
}

.clearfix:after,
.container:after,
.container-fluid:after,
.row:after,
.form-horizontal
.form-group:after,
.btn-toolbar:after,
.btn-group-vertical>.btn-group:after,
.nav:after,
.navbar:after,
.navbar-header:after,
.navbar-collapse:after,
.pager:after,
.panel-body:after,
.modal-footer:after {
  clear: both;
}

// CLEARFIX like Bootstrap one!
.clearfix {
  *zoom: 1;

  &:before,
  &:after {
    content: " ";
    display: table;
  }

  &:after {
    clear: both;
  }
}

// CONTAINER like Bootstrap one!
.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

// LIST elements (lu) like Bootstrap one!
ul {
  -webkit-margin-after: 10px;
  -webkit-margin-before: 0;
}

// IMG Responsive like Bootstrap one!
.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  border: 0;
}

// IMG inside a circle like Bootstrap one!
.img-circle,
.circle,
.circle img {
  border-radius: 50%;
}

// HIDDEN class like Bootstrap one!
.hidden {
  display: none!important;
  visibility: hidden!important;
}