/**
 ******************************************************
 ******************************************************
 * {LESS} :: COMMON :: (upplication, 2016)
 ******************************************************
  *****************************************************
 */

// to add typical Bootstrap classes & pseudoelements
@import "_elements.less";
// main top header
@import "_header.less";
// error and alert messages
@import "_alerts.less";
// bottom cookies advise
@import "_cookies.less";
@import "_materialize-resets.less";
@import "_mediaqueries.less";
// all Modals dialogs
@import "_modals.less";
// Register form
@import "_register.less";
// Variables:
@import "_mixins.less";
@import "_variables.less";
// all blocks in Footer:
@import "_trial.less";
@import "_clients.less";
@import "_footer.less";
@import "_contact.less";

// Google fonts
@import url('https://fonts.googleapis.com/css?family=Lato:100,400,700,900|Raleway:100,400,600,700,900|Roboto+Slab:100,400,700');


/* ... GENERAL FONTS ... */

html,
body {
  font-family: 'Lato', sans-serif;
  font-size: 100%;
  background: @white;
  overflow: auto;
}

/* ... ANIMATIONS ... */

body a {
  transition: 0.5s all;
  -webkit-transition: 0.5s all;
  -ms-transition: 0.5s all;
}

.hover-anim,
.hover-anim li {
  -webkit-transition: all 200ms ease-in;
  -ms-transition: all 200ms ease-in;
  transition: all 200ms ease-in;
}

.total-fix {
  margin: 0 !important;
  padding: 0 !important;
}

/* ... TRANSFORMS ... */

.skew-right {
  -webkit-transform: skew(0deg, -3deg);
  -ms-transform: skew(0deg, -3deg);
  transform: skew(0deg, -3deg);
}

.skew-left {
  -webkit-transform: skew(0deg, 3deg);
  -ms-transform: skew(0deg, 3deg);
  transform: skew(0deg, 3deg);
}

/* ... to Fix Upplication Icons position ... */

i.icon {
  position: relative;
  top: 2px;
  font-weight: 100;
}

/* ... TEXT SELECTED COLOR ... */

::selection {
  background: @main-green;
  color: @white;
}

::-moz-selection {
  background: @main-green;
  color: @white;
}

::-webkit-selection {
  background: @main-green;
  color: @white;
}

/* ... CUSTOM SCROLL BAR ... */

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background: lighten(@light-lilac, 10%);
  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  background: @white;
  border: 0 solid transparent;
  border-radius: 0;
  height: 5em;
}

/* ... to add Shadow Hover Effect in images ... */

img.materialboxed:hover {
  .box-shadow;
  .anim(all, 250ms, ease-in-out);
}

/* ... MAIN HEADERS (SECTIONS) ... */

.headTitles,
h4.headTitles,
h2.headTitles {
  color: @darkest-lilac;
  font-family: "Raleway", sans-serif;
  font-size: 2em;
  font-weight: 500;
  line-height: 45px;
  margin: 1em 0;
  padding: 0.5em;
  
  span {
    color: @medium-lilac;
  }
}

@media all and (max-width: 480px) {
  .headTitles,
  h4.headTitles,
  h2.headTitles {
    font-size: 1.8em;
    margin: 0;
  }
}

h4:not(.head-terms).headTitles + p,
.plans-head p {
  color: @medium-lilac;
  line-height: 1em;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 30px;
  font-size: 1.2em;
}

.headTitles.noMargBot {
  margin-bottom: 0;
}

/* ... PADDING ... */

.sect100 {
  padding: 100px 0;
}

.padTop50 {
  padding-top: 50px;
}

.padTop30 {
  padding-top: 30px;
}

.padBot10 {
  padding-bottom: 10px;
}

.padBot30 {
  padding-bottom: 30px;
}

.padBot50 {
  padding-bottom: 50px;
}

/* ... MARGIN ... */

.margTop30 {
  margin-top: 30px;
}

.marBot50 {
  margin-bottom: 50px;
}

.marBot10 {
  margin-bottom: 10px;
}

.marBot100 {
  margin-bottom: 100px;
}

/* ... UPP MAIN BUTTON ... */

.btn-upp {
  background-color: @accept-btn;
  color: @white;
  border-radius: 0;

  &:hover,
  &:focus {
    background-color: @accept-btn-hover;
    color: @white;
  }

  &:active,
  &:visited {
    background-color: @accept-btn;
    color: @white;
  }
}

/* ... to add Block styles... */

.block-btn {
  display: block;
  width: 100%;
  padding: 12px 20px;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: .02em;
}

/* ... FONT WEIGHTS ... */

.strong500 {
  font-weight: 500 !important;
}

/* ... BORDERS ... */

.display-none {
  display: none;
}

.border-bottom {
  border-bottom: 1px solid @bg-lilac-light;
}

/* ... BORDER DIV ... */

.spacer-border-grey {
  width: 100%;
  height: 1px;
  border-bottom: 1px solid @bg-lilac-light;
}


/* ... DIAGONAL BLOCK LAYOUT to Home & Microlandings views ... */

.dippah {
  position: relative;
  bottom: 47px;
  z-index: 100;
  width: 100%;
  height: 90px;
  background-color: white;
}

@media only screen and (max-width: 768px) {
  .dippah {
    display: none;
  }
}

/* ... ANIMATIONS on icons in Desktop and Big screens devises ... */

@media only screen and (min-width: 768px) {
  // Rocket icon with "smooth flight" animation (_mixins.less)
  a,
  button {
    &:hover {
      .icon.icon-rocket {
        .animate(smooth-flight, 1s, infinite);
      }
    }
  }

  .trial-block {
    .trial-block-right {
      a.price-btn {
        &:hover {
          .icon.icon-rocket {
            .animate(smooth-flight, 1s, infinite);
          }
        }
      }
    }
  }
}

// TODO: to hide "Marketing App" course Modal (dialog)
/* -- MARKETING APP eBook -- */
html div#om-gylasdx7iwcdo5a40ykm,
html div#om-gylasdx7iwcdo5a40ykm-holder,
#om-gylasdx7iwcdo5a40ykm,
#om-gylasdx7iwcdo5a40ykm-holder,
// (custom) Class to hide DOM elements
.hidden-modal {
  display: none !important;
  visibility: hidden !important;
  opacity: 0 !important;
}