/**
 ******************************************************
 ******************************************************
 * {LESS} :: REGISTER FORM :: (upplication, 2016)
 ******************************************************
  *****************************************************
 */

@import (reference) "_common.less";


// Chrome browser default values resert
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  background-color: rgb(255, 255, 255) !important;
}


/* ... Main REGISTER FORM Block to desktop, big screens & no touch devises ... */

.register-form {
  background-color: rgba(255,255,255,.9);
  position: absolute;
  z-index: 200;
  top: 148px;
  right: 7%;
  padding: 0;
  max-width: 400px;
  min-width: 300px;

  .register-top {
    margin-left: 0rem;
    margin-right: 0rem;
    margin-bottom: 0;

    .register-form-block {
      padding: 20px 20px 10px 20px !important;

      // Register Form Input Fields
      .input-field {
        margin-bottom: 5px;
        margin-top: auto;
        &:first-child {
          //margin-top: 5px;
        }
        .tos-text{
          margin-top: 0px;
          font-size: small;
          text-align: justify;
        }
        label {
          color: @dark-lilac;
        }
      }

      // Register Form Button
      .register-form-btn {
        text-align: center;

        button.btn-upp {
          width: 100%;
          margin-top: 10px;

        }

        // under BTN copy
        p {
          margin-top: 10px;
          text-align: center;
          color: @darkest-lilac;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }
}

/* ... Call to Action :: MOBILE ONLY ... */

.register-form-mobile {
  margin-top: 10px;

  a {
    padding: .8em .5em !important;
    color: @white;
    font-weight: 500 !important;
    letter-spacing: .05em;
  }
}




.register-top .register-form-btn span,
#host .btn-upp span {
  font-weight: 500;
  letter-spacing: 0.04em;
}



/**
 ******************************************************
 * MEDIA QUERIES :: REGISTER FORM :: (upplication, 2016)
 ******************************************************
 */

@media only screen and (min-width: 2000px) {
  .register-form {
    right: 20% !important;
  }
}

@media only screen and (max-width: 1950px) {
  .register-form {
    right: 19% !important;
  }
}

@media only screen and (max-width: 1950px) {
  .register-form {
    right: 18% !important;
  }
}

@media only screen and (max-width: 1900px) {
  .register-form {
    right: 17% !important;
  }
}

@media only screen and (max-width: 1850px) {
  .register-form {
    right: 16% !important;
  }
}

@media only screen and (max-width: 1800px) {
  .register-form {
    right: 15% !important;
  }
}

@media only screen and (max-width: 1750px) {
  .register-form {
    right: 14% !important;
  }
}

@media only screen and (max-width: 1700px) {
  .register-form {
    right: 13% !important;
  }
}

@media only screen and (max-width: 1650px) {
  .register-form {
    right: 12% !important;
  }
}

@media only screen and (max-width: 1600px) {
  .register-form {
    right: 11% !important;
  }
}

@media only screen and (max-width: 1500px) {
  .register-form {
    right: 10% !important;
  }
}

@media only screen and (max-width: 1400px) {
  .register-form {
    right: 10% !important;
  }
}

@media only screen and (max-width: 1300px) {
  .register-form {
    right: 9% !important;
  }
}

@media only screen and (max-width: 1280px) {
  .register-form {
    right: 30px !important;
  }
}

@media only screen and (max-width: 1080px) {
  .register-form {
    right: 30px;
  }

  .register-form {
    top: 162px;
  }
}

/* HEIGHTS stoppers */

@media only screen and (max-height: 568px) {
  .register-form-mobile {
    bottom: 95px;
  }
}


