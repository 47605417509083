/**
 ************************************************************
 * (component) :: IMAGES CAROUSEL :: (upplication, 2016)
 ************************************************************
 */

/* ... CLIENTS CAROUSEL :: PreFooter Common in all pages... */

.clients-section {
  background: rgba(234,255,245,1);
  background: -webkit-linear-gradient(-45deg, rgba(234,255,245,1) 0%, rgba(149,255,206,0.9) 48%, rgba(98,255,182,0.8) 100%);
  background: -ms-linear-gradient(-45deg, rgba(234,255,245,1) 0%, rgba(149,255,206,0.9) 48%, rgba(98,255,182,0.8) 100%);
  background: -webkit-linear-gradient(315deg, rgba(234, 255, 245, 1) 0%, rgba(149,255,206,0.9) 48%, rgba(98,255,182,0.8) 100%);
  background: linear-gradient(135deg, rgba(234,255,245,1) 0%, rgba(149,255,206,0.9) 48%, rgba(98,255,182,0.8) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eafff5', endColorstr='#62ffb6', GradientType=1 );
  padding: 3em 0;
  margin-top: 8em;

  .item {
    margin: 3px;

    img {
      opacity: .5;
      display: block;
      width: auto;
      height: 40px;
      position: relative;
      left: 50%;
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      -o-transform: translateX(-50%);
      transform: translateX(-50%);

      &:hover {
        opacity: .8;
      }
    }
  }
}