/**
 ******************************************************
 ******************************************************
 * {LESS} :: HEADER (base) :: (upplication, 2016)
 ******************************************************
  *****************************************************
 */

@import (reference) "_common.less";

/**
**********************************
* main nav TOP HEADER
**********************************
*/

.top-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  height: 80px;
  background-color: @header-bg;
  border-bottom: 1px solid @header-border;
  .anim(height, 0.3s, ease);

  // container Header inside
  .container {
    padding: 10px 0 0 0;
    .anim(all, 300ms, ease);
    // responsive behaviour
    @media screen and (max-width: 1280px) {
      padding: 10px 10px 0 10px;
    }

    // Right Side Main LOGO
    .logo {
      float: left;
      margin-top: 0;

      a:not(.bst) {
        display: block;
        text-decoration: none;

        img {
          width: 240px;
          height: 66px;
          position: relative;
          top: -4px;
          .anim(all, 300ms, ease);
        }

        // logo img animation hover effect
        &:hover {
          img {
            .scale(0.9);
          }
        }
      }
    }

    // Left Side Main NAVBAR
    .top-nav {
      float: right;
      margin-top: 10px;

      .top-nav-list {
        line-height: 35px;
        border-bottom: 1px solid transparent;

        li {
          line-height: inherit;

          // main header nav links
          a {
            color: @dark-lilac;
            padding: 0.2em 0.7em;
            max-height: 40px;
            .font-family-header;
            font-size: 16px;
            letter-spacing: .02em;
            font-weight: 600;
            text-align: center;
            position: relative;
            max-width: 195px;
            .ellipsis;
            .anim(all, 150ms, ease-in);

            &:hover {
              background-color: transparent;
              color: @main-green;
            }

            &.active {
              color: @darkest-lilac;
            }

            i {
              padding-right: 5px;
              .icon-default;

              &.icon-key {
                font-size: 1.3em;
                top: 3px;
              }
            }
          }

          &.enter-btn {
            a {
              border: 2px solid @accept-btn;
              color: @accept-btn;
              margin: 0 8px;

              &:hover {
                border: 2px solid @accept-btn-hover;
                color: @accept-btn-hover;

                i {
                  .animate(in-out-move, 1s, infinite);
                }
              }
            }
          }

          // "Free trial" BTN & Contact Info block
          &.contact-info {

            a {
              .anim(all, 150ms, ease-in);
            }

            .contact-info-grids {
              min-width: 280px;

              .contact-info-left {
                float: left;
                width: 50%;

                .chat {
                  min-width: 95%;
                  background: @accept-btn;
                  color: @white;
                  font-weight: 600;
                  text-decoration: none;

                  &:hover {
                    background-color: @accept-btn-hover;
                  }
                }
              }

              .contact-info-right {
                float: right;
                width: 50%;
                text-align: center;
                margin-top: -2px;

                a {
                  display: block;
                  padding: 0 5px;
                  font-size: 15px;
                  .font-family-main;
                  text-align: left;
                  cursor: text;
                  line-height: 20px;

                  &.support {
                    margin-bottom: 0;
                    padding-top: 5px;
                    color: @darkest-lilac;
                    font-size: 13px;
                    font-weight: 400;
                    letter-spacing: 0.02em;
                    text-align: center;
                  }

                  &:hover {
                    color: @accept-btn-hover;
                  }
                }
              }
            }
          }
        }
      }
    }

    #dropdown-lang,
    #dropdown-terms {
      margin-top: 59px;
    }
  }

  // slim Header :: animation when you scrolling
  &.thiny-top-header {
    height: 60px;

    .container {
      padding-top: 0;

      .logo {
        a {
          img {
            width: 200px;
            height: 55px;
            top: 3px;
          }
        }
      }

      #dropdown-lang,
      #dropdown-terms {
        margin-top: 48px;
      }
    }
  }
}


/* ... THREE animated BARS icon BTN for drop down main navbar in mobile ... */

@media only screen and (min-width : 769px) {
  nav a#pull {
    display: none;
  }
}

@media only screen and (max-width : 768px) {
  nav a#pull {
    display: block;
    text-align: right;
    position: absolute;
    top: 18px;
    right: 20px;
    width: 35px;
    height: 35px;

    span {
      display: block;
      position: absolute;
      height: 4px;
      width: 100%;
      background: @main-green;
      opacity: 1;
      left: 0;
      .rotate(0);
      .anim(all, 250ms, ease-in-out);

      &:nth-child(1) {
        top: 0;
      }
      &:nth-child(2),
      &:nth-child(3) {
        top: 10px;
      }
      &:nth-child(4) {
        top: 20px;
      }
    }

    &:after {
      content: "";
      width: 30px;
      height: 30px;
      display: inline-block;
      position: absolute;
      right: 15px;
      top: 10px;
    }

    // Three Bars nav icon btn (dropdown menu close) turns to cross (dropdown menu close)
    &.open {
      span {
        background: @light-lilac;

        &:nth-child(1) {
          top: 18px;
          width: 0%;
          left: 50%;
        }
        &:nth-child(2),
        &:nth-child(3) {
          .rotate(45deg);
        }
        &:nth-child(4) {
          top: 10px;
          -webkit-transform: rotate(-45deg);
          -moz-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
          -o-transform: rotate(-45deg);
          transform: rotate(-45deg);
        }
      }
    }
  }
}



// Common styles to dropdown subnavs in main header top navbar
#dropdown-lang,
#dropdown-terms {
  background-color: @header-bg;
  border: 1px solid @light-lilac;
  border-top: none;

  li {
    a {
      text-align: left;
      padding-left: 18px;
    }

    &:hover {
      background-color: transparent;
    }
  }
}

#dropdown-lang {
  margin-left: 14px;
}

#dropdown-terms {
  margin-left: -6px;
}


// to hide elements in mobile (responsive behaviour)
.hide-tabs {
  display: none !important;
}



/* ... LANDING-UPP :: older styles remains ... */

.logo320 {
  display: none;
}



/**
**********************************
* main Header :: MEDIA QUERIES
**********************************
*/

@media only screen and (max-width: 1200px) {

  nav ul.top-nav {
    float: right;
  }

  .contact-info p {
    display: none;
  }

  .contact-info-right h3 {
    display: none;
  }

  .contact-info-left {
    float: right;
    top: 9px;
    width: 100%;
  }

  .contact-info-right {
    display: none;
  }
}


@media only screen and (max-width: 1200px) {
  .top-header {
    .container {
      .top-nav {
        .top-nav-list {
          li.contact-info {
            .contact-info-grids {
              min-width: inherit;

              .contact-info-left {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1030px) {
  .top-header {
    .container {
      .logo {
        a {
          img {
            width: 160px;
            padding-top: 10px;
          }
        }
      }
    }
    &.thiny-top-header {
      .container {
        .logo {
          a {
            img {
              padding-top: 0;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 980px) {
  .top-header {
    .container {
      .top-nav {
        .top-nav-list {
          li.contact-info {
            .contact-info-grids {
              .contact-info-left {
                .chat {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 769px) and (max-width: 850px) {
  .top-header {
    .container {
      .top-nav {
        .top-nav-list {
          li {
            a {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}


@media only screen and (min-width: 767px) {
  // removed cause it�s only for mobile
  .only-show-small-devises {
    display: none;
  }
  // to fix width in big screens
  .top-header {
    .container {
      #dropdown-terms {
        width: 175px !important;
      }
      #dropdown-lang {
        width: 130px !important;
      }
    }
  }
}


// TABLETS
@media only screen and (max-width: 768px) {
  .top-header,
  .top-header.thiny-top-header {
    height: 60px;

    .container {
      padding: 0;

      .logo {
        padding-left: 15px;

        a {
          img {
            width: 160px;
            top: 0;
            padding-top: 0;
          }
        }
      }

      .top-nav {
        float: inherit;
        margin-top: 59px;

        .top-nav-list {
          position: absolute;
          float: left;
          width: 100%;
          display: none;
          height: auto;
          margin: 0;
          background-color: @header-bg;
          border-bottom: 1px solid @header-border;

          li {
            width: 33.3333%;
            background-color: @header-bg;
            text-align: center;

            &.only-show-small-devises {
              // display: inline-block;
              display: none;
            }

            &.languages,
            &.more,
            &.enter-btn {
              border-bottom: 1px solid @header-border;
            }

            a {

            }
          }
        }
      }

      // main top navbar dropdowns
      #dropdown-lang,
      #dropdown-terms {
        margin-top: 10px;
        border: none;

        li {
          width: 100%;
          border-left: 1px solid @header-border;
          border-right: 1px solid @header-border;

          &:last-child {
            border-bottom: 1px solid @header-border;
          }

          a {
            text-align: left;
            padding-left: 18px;
            color: @medium-lilac;
          }
        }
      }

      #dropdown-terms {
        margin-left: 4%;
      }

      #dropdown-lang {
        margin-left: 6%;

        &.notshow {
          display: none !important;
        }
      }
    }
  }

  // to reset main Logo position when you scroll down
  .top-header.thiny-top-header {
    .container {
      .logo {
        a {
          img {
            top: 7px;
          }
        }
      }
    }
  }
}


// MEDIA QUERIES :: Smartphone :: LANDSCAPE
@media only screen and (max-width : 480px) {
  .top-header,
  .top-header.thiny-top-header {
    .container {
      .top-nav {
        .top-nav-list {
          li {
            width: 50%;

            &.more {
              border-bottom: none;
            }

            a {

            }
          }
        }
      }
    }
  }
}


// MEDIA QUERIES :: Smartphone :: PORTRAIT
@media only screen and (max-width : 320px) {
  .top-header,
  .top-header.thiny-top-header {
    .container {
      .top-nav {
        .top-nav-list {
          li {
            width: 100%;
            display: block;
            float: none;
            border-bottom: none !important;

            &.contact-info {
              display: none;
            }

            a {
              max-width: inherit;
            }
          }
        }
      }

      #dropdown-lang,
      #dropdown-terms {
        margin-left: 0;

        li {
          border: none;

          a {
            text-align: center;
          }
        }
      }
    }
  }
}


// Media Queries :: HEIGHT
@media only screen and (max-height: 575px) {
  .top-header,
  .top-header.thiny-top-header {
    .container {
      #dropdown-terms {
        max-height: 150px;
      }
    }
  }
}


/*
-------------------------------------------
  XMAS DECORATION :: (color) LIGHTS BULBS
-------------------------------------------

@globe-width:       8px;
@globe-height:      8px;
@globe-spacing:    40px;
@globe-spread:      3px;
@light-off-opacity: 0.4;

.lightrope {
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  position: absolute;
  z-index: 1;
  margin: -9px 0 0 0;
  padding: 0;
  pointer-events: none;
  width: 100%;

  li {
    list-style: none;
    display: inline-block;
    width: @globe-width;
    height: @globe-height;
    margin: @globe-spacing/4 @globe-spacing/2 @globe-spacing/2;
    padding: 0;
    position: relative;
    .border-radius(50%);
    background: rgba(0,247,165,1);
    box-shadow: 0 @globe-height/6 @globe-width*2 @globe-spread rgba(0,247,165,1);
    animation-name: flash-1;
    animation-duration: 2s;
    animation-fill-mode: both;
    animation-iteration-count:infinite;
    &:nth-child(2n+1) {
      background: rgba(241,246,83,1);
      box-shadow: 0 @globe-height/6 @globe-width*2 @globe-spread rgba(241,246,83,0.5);
      animation-name: flash-2;
      animation-duration: 0.4s;
    }
    &:nth-child(4n+2) {
      background: rgba(247,0,148,1);
      box-shadow: 0 @globe-height/6 @globe-width*2 @globe-spread rgba(247,0,148,1);
      animation-name: flash-3;
      animation-duration: 1.1s;
    }
    &:nth-child(odd) {
      animation-duration: 1.8s;
    }
    &:nth-child(3n+1) {
      animation-duration: 1.4s;
    }
    &:before {
      content: "";
      width: 10px;
      height: 3px;
      background: @light-lilac;
      .border-radius(3px);
      position: absolute;
      top: -2px;
      left: -1px;
    }
    &:after {
      content: "";
      width: @globe-spacing + 12;
      height: (@globe-height/3 * 2);
      .border-radius(50%);
      border-bottom: solid @light-lilac 1px;
      position: absolute;
      top: (0 - @globe-height/2);
      left: @globe-width - 3;
    }
    &:last-child:after {
      content: none;
    }
    &:first-child {
      margin-left: -@globe-spacing;
    }
  }
}
@keyframes flash-1 {
  0%, 100% {
    background: rgba(0,247,165,1);
    box-shadow: 0 @globe-height/6 @globe-width*2 @globe-spread rgba(0,247,165,1);
  }
  50% {
    background: rgba(0,247,165,@light-off-opacity);
    box-shadow: 0 @globe-height/6 @globe-width*2 @globe-spread rgba(0,247,165,0.2);
  }
}
@keyframes flash-2 {
  0%, 100% {
    background: rgba(241,246,83,1);
    box-shadow: 0 @globe-height/6 @globe-width*2 @globe-spread rgba(241,246,83,1);
  }
  50% {
    background: rgba(0,255,255,@light-off-opacity);
    box-shadow: 0 @globe-height/6 @globe-width*2 @globe-spread rgba(241,246,83,0.2);
  }
}
@keyframes flash-3 {
  0%, 100% {
    background: rgba(247,0,148,1);
    box-shadow: 0 @globe-height/6 @globe-width*2 @globe-spread rgba(247,0,148,1);
  }
  50% {
    background: rgba(247,0,148,@light-off-opacity);
    box-shadow: 0 @globe-height/6 @globe-width*2 @globe-spread rgba(247,0,148,0.2);
  }
}
// Media Queries :: XMAS decoration
@media only screen and (max-width: 768px) {
  .lightrope {
      margin: -77px 0 0 0;
  }
}

// End of COLOR LIGHTS XMAS DECORATION
*/