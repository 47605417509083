/**
 ****************************************************************
 * (component) :: THREE CARDS GRID layout :: (upplication, 2016)
 ****************************************************************
 */

/* ... Included in "Home" (index), "About Us" (Team) & "Showcases" (Testimonials) pages ... */

.about-grids {
  margin-top: 1.5em;
  margin-right: 0;
  margin-left: 0;
  padding: 0;
  background: @bg-lilac-light;

  &.row {
    .col {
      padding: 0;
    }
  }

  .about-grid {

    // TEXT area
    .about-grid-info {
      padding: 2em 2.5em;

      h4 {
        font-family: "Lato", sans-serif;
        margin: 0 0 1.5em 0;
        padding: 0 0 0.5em 0;
        font-size: 1.3em;
        font-weight: 400;
        letter-spacing: 0.03em;
        text-decoration: none;

        span {
          font-weight: 600;
        }
      }

      p {
        font-size: 18px;
        line-height: 25px;
        font-weight: 400;
        text-align: justify;

        &.grid-link {
          margin-bottom: 0;

          a {
            font-weight: 400;

            i {
              top: -1px;
              font-size: 14px;
              padding-left: 5px;
              -webkit-transition: padding 0.3s;
              -moz-transition: padding 0.3s;
              -ms-transition: padding 0.3s;
              -o-transition: padding 0.3s;
              transition: padding 0.3s;

              &:before {
                .rotate(180deg);
              }
            }

            &:hover {
              i {
                padding-left: 20px;
              }
            }
          }
        }
      }
    }

    // PICs & VIDEO areas
    .about-grid-pic {

      img {
        width: 100%;
      }

      // to preload youtube iframes
      .video-container {
        img {
          width: 100%;
          position: absolute;
          top: -17%;
        }
      }
    }

    // Backgrounds Colors
    &.about-grid1 {
      background: @main-green;

      .about-grid-info {
        p,
        h4,
        a {
          color: @white;
        }
        h4 {
          border-bottom: 1px solid @white;
        }
      }
    }
    &.about-grid2 {
      background: @bg-lilac-light;

      .about-grid-info {
        p,
        h4,
        a {
          color: @darkest-lilac;
        }
        h4 {
          border-bottom: 1px solid @darkest-lilac;
        }
      }
    }
  }
}

// Media Queries to change the Blocks
@media (min-width: 769px) and (max-width: 1280px) {
  .about-grids {
    .about-grid.about-grid1,
    .about-grid.about-grid2 {
      width: 100%;

      .about-grid-pic,
      .about-grid-info {
        width: 50%;
      }
    }

    .about-grid.about-grid1 {
      .about-grid-info {
        float: left;
      }
      .about-grid-pic {
        float: right;
      }
    }

    .about-grid.about-grid2 {
      .about-grid-info {
        float: right;
      }
      .about-grid-pic {
        float: left;
      }
    }
  }
}

@media (max-width: 980px) {
  .about-grids {
    .about-grid {
      .about-grid-info {
        padding: 2.3em 2.5em 0.1em 2.5em;

        h4 {
          margin-bottom: 15px;
        }
      }
    }
  }
}

@media (max-width: 850px) {
  .about-grids {
    .about-grid {
      .about-grid-info {
        padding: 1.5em 2em 0.1em 2em;

        p {
          font-size: 17px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .about-grids {
    .about-grid.about-grid1,
    .about-grid.about-grid2,
    .about-grid,
    .col.m4 {
      width: 100%;

      .about-grid-pic,
      .about-grid-info {
        width: 100%;
        float: left;
      }
      .about-grid-info {
        padding: 2.3em 2.5em 2.3em 2.5em;
      }
    }
  }
}

@media (max-width: 480px) {
  .about-grids {
    .about-grid {
      .about-grid-info {
        p {
          .font-mobile;
        }
      }
    }
  }
}


/* ... Media Queries :: Three Cards video-image layout :: HEIGHT */

@media only screen and (max-height: 667px) {
  #about {
    padding-top: 0;
  }
}
