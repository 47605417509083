/**
 ******************************************************
 ******************************************************
 * {LESS} :: CONTACT :: (upplication, 2018)
 ******************************************************
  *****************************************************
 */
@import (reference) "_common.less";

#contact {
  background: rgba(222, 225, 236, 1) url("../../../images/home/bg/lines-d2.png") repeat-y top/100vw;
  color: @main-green;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  z-index: -100 ;
  font-weight: 500;
  padding: 2vw 0 2vw 0;

}
.titulo{
  font-weight: 700;
  font-size: 3.8vw;
}
#contact .ct-t2 {
  margin-top: 1.4vw;
}
#contact .ct-t1{
  font-size: 1.5vw;
  margin-top: 1.85vw;
  line-height: 1.3;
  letter-spacing: -0.034em;
  padding-left: 0.11vw;
  text-align: justify;
  text-justify: inter-word;
}
#contact .ct-t2{
  font-size: 2vw;
}
#contact input, #contact textarea{
  background-color: #d5d8e2;
  color: @main-green;
  padding-left: 3px;
}
input::placeholder, textarea::placeholder {
  color: @main-green;
}
input:disabled {
  pointer-events: none;
  background-color: #DFDFDF !important;
  box-shadow: none;
  color: #9F9F9F !important;
  cursor: default;
}
textarea{
  border: none;
  outline: none;
  height: 6.9vw;
  resize: none;
  border-bottom: 1px solid #9e9e9e;
  margin-bottom: 1.2vw;
}

textarea:focus{
  outline: none !important;
  border-bottom: 2px solid @main-green;
  box-shadow: 0 0 10px rgba(237, 253, 235, 0.04);
}
#contact input[type=submit]{
  width: 100%;
  margin-top: 1.3vw;
  text-align: center;
  color: #fff;
  background: @main-green;
  border: none;
  padding: 0.75vw 0 1.15vw;
  letter-spacing: 0;
  cursor: pointer;
  -webkit-appearance: none;
}
#contact input[type=submit]:hover{
  background-color: rgba(0, 226, 121, 0.71);
}

@media only screen and (max-width: 900px) {
  .titulo{
    margin-top: 2.35vw;
    font-size: 5vw;
    max-width: 100%;
  }
  #contact .ct-t1, #contact .ct-t2 {
    margin-top: 5.35vw;
    font-size: 3vw;
    max-width: 100%;
    margin-botom: 1.5vw;
    width: 100%;
  }
  #contact input[type=submit] {
    margin: 2.75vw 0 2.75vw;
    padding: 2vw 5vw 2vw 5vw;
  }
  #contact textarea {
    height: 13vw;
  }
}

@media only screen and (max-width: 736px) {
  .titulo{
    margin-top: 5.35vw;
    font-size: 11.574vw;
    max-width: 100%;
  }
  #contact .ct-t1, #contact .ct-t2 {
    margin-top: 5.35vw;
    font-size: 4.55vw;
    max-width: 100%;
    margin-bottom: 2.5vw;
  }
}