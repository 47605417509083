/**
 ***************************************************************************
 * (component) :: MAIN TOP TITLE CLAIMS (H1 & H2)  :: (upplication, 2016)
 ***************************************************************************
 */

/* ... MAIN TITLE & SUBTITLE (main text just over the top slider) :: Home (index) view ... */

.main-clain-on-head {
  color: @white;
  position: absolute;
  top: 75px;
  left: 10%;
  width: 70%;
  text-shadow: 1px 1px 1px @black;
  z-index: 200;

  // Second Header in Top Slider (Home view)
  h2 {
    font-size: 2.6em;
    text-transform: uppercase;
    font-weight: 600;
    background: rgba(0,0,0,.7);
    display: inline-block;
    line-height: 1.1em;
    padding-left: 10px;
    padding-right: 0;
    position: relative;

    &::before {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 45px 10px;
      border-color: transparent transparent rgba(0,0,0,.7) transparent;
      position: absolute;
      left: -10px;
    }

    &::after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 45px 28px 0 0;
      border-color: rgba(0,0,0,.7) transparent transparent transparent;
      position: absolute;
      right: -28px;
      top: 0;
    }

    // Changing Text with animation effect
    .typed-effect {
      display: inline-block;

      span {
        display: inline-block;
      }
    }

    span {
      color: @main-green;
      font-weight: 600;
      padding-right: 5px;
    }
  }

  // Main Header in Top Slider (Home view)
  h1 {
    font-size: 1.6em;
    font-weight: 500;

    span {
      background-color: rgba(0,0,0,.7);
      display: table;
      padding: 5px;
      margin: 10px 0;
      max-width: 450px;

      // Second row main clain text
      &.intro-2b {
        margin-left: -6px;

        &::after {
          content: '';
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 39px 14px 0 0;
          border-color: rgba(0,0,0,.7) transparent transparent transparent;
          position: relative;
          right: -19px;
          top: 35px;
        }
      }
    }
  }
}




/* ... Media Queries :: Three Cards video-image layout :: WIDTH */

@media only screen and (max-width: 1600px) {
  .main-clain-on-head h1 span.intro-2b::after {
    border-width: 34px 14px 0 0;
    top: 30px;
  }
}

@media only screen and (max-width: 1380px) {
  .main-clain-on-head h2 {
    font-size: 2.5em;
  }

  .main-clain-on-head h2::before {
    top: -1px;
  }
}

@media only screen and (max-width: 1325px) {
  .main-clain-on-head h2 {
    font-size: 2.4em;
  }

  .main-clain-on-head h2::after {
    border-width: 42px 28px 0 0;
  }
}

@media only screen and (max-width: 1280px) {
  .main-clain-on-head {
    left: 60px;
    width: 90%;
  }

  .main-clain-on-head h2::before {
    border-width: 0 0 43px 10px;
  }
}

@media only screen and (max-width: 1180px) {
  .main-clain-on-head h1 {
    padding-top: 10px;
  }
}

@media only screen and (max-width: 1001px) {
  .main-clain-on-head h2 {
    font-size: 2.2em;
  }

  .main-clain-on-head h2::before {
    border-width: 0 0 39px 10px;
  }

  .main-clain-on-head h2::after {
    border-width: 37px 28px 0 0;
  }
}

@media only screen and (max-width: 980px) {
  .main-clain-on-head {
    h1 {
      font-size: 1.4em;
      max-width: 350px;
    }
  }
}

@media only screen and (max-width: 940px) {
  .main-clain-on-head {
    h2 {
      font-size: 2em;

      &::before {
        border-width: 0 0 36px 10px;
      }
      &::after {
        border-width: 35px 28px 0 0;
      }
    }
  }
}

@media only screen and (max-width: 885px) {
  .main-clain-on-head h2 {
    font-size: 1.9em;
  }

  .main-clain-on-head h2::before {
    border-width: 0 0 34px 10px;
  }

  .main-clain-on-head h2::after {
    border-width: 33px 28px 0 0;
  }

  .main-clain-on-head h1 {
    font-size: 1.4em;
  }

  .main-clain-on-head h1 span.intro-2b::after {
    border-width: 34px 14px 0 0;
    top: 30px;
  }
}

@media only screen and (max-width: 860px) {
  .main-clain-on-head {
    max-width: 300px;

    h1 {
      font-size: 1.4em;
    }
  }
}

@media only screen and (max-width: 830px) {
  .main-clain-on-head h2 {
    font-size: 1.8em;
  }

  .main-clain-on-head h2::before {
    border-width: 0 0 32px 10px;
  }

  .main-clain-on-head h2::after {
    border-width: 31px 28px 0 0;
  }

  .main-clain-on-head h1 {
    font-size: 1.2em;
  }

  .main-clain-on-head h1 span.intro-2b::after {
    border-width: 31px 14px 0 0;
    top: 27px;
    right: -19px;
  }
}

@media only screen and (max-width: 768px) {

  .team-header {
    margin-top: 6em;
  }

  .main-clain-on-head {
    top: 90px;
    width: 89%;
    left: 30px;
  }

  .main-clain-on-head h2 {
    font-size: 1.7em;
    margin: 0;
    padding-bottom: 5px;
  }

  // two lines main text for small devices
  .main-clain-on-head h2 .typed-effect {
    display: block;
  }

  .main-clain-on-head h2 span {
    display: inline-block;
    margin-top: 5px;
  }

  .main-clain-on-head h2::before {
    border-width: 0 0 40px 16px;
    left: -16px;
  }

  .main-clain-on-head h2::after {
    border-width: 39px 19px 0 0;
    right: -19px;
  }

  .main-clain-on-head h1 {
    font-size: 1.3em;
    margin: 0;
    max-width: inherit;
  }

  .main-clain-on-head h1 span {
    display: inline-block;
  }

  .main-clain-on-head h1 span.intro-2b {
    margin-left: -5px;
  }
}

@media only screen and (max-width: 720px) {
  .main-clain-on-head h2 {
    font-size: 1.5em;
    margin: 0;
    padding-bottom: 5px;
  }

  .main-clain-on-head h2::before {
    border-width: 0 0 37px 16px;
    left: -16px;
  }

  .main-clain-on-head h2::after {
    border-width: 36px 19px 0 0;
    right: -19px;
  }
}

@media only screen and (max-width: 655px) {
  .main-clain-on-head h2 {
    font-size: 1.3em;
  }

  .main-clain-on-head h2::before {
    border-width: 0 0 33px 16px;
  }

  .main-clain-on-head h2::after {
    border-width: 32px 19px 0 0;
  }

  .main-clain-on-head h1 {
    font-size: 1em;
  }

  .main-clain-on-head h1 span.intro-2b::after {
    border-width: 27px 14px 0 0;
    top: 23px;
  }

  .main-clain-on-head h1 span.intro-2b {
    margin-left: -4px;
  }
}

@media only screen and (max-width: 560px) {
  .main-clain-on-head h2 {
    font-size: 1.1em;
  }

  .main-clain-on-head h2::before {
    border-width: 0 0 30px 16px;
  }

  .main-clain-on-head h2::after {
    border-width: 29px 19px 0 0;
  }

  .main-clain-on-head h1 {
    font-size: .8em;
  }

  .main-clain-on-head h1 span.intro-2b::after {
    border-width: 24px 14px 0 0;
    top: 20px;
  }
}

@media only screen and (max-width: 510px) {
  .main-clain-on-head h2 {
    font-size: 1em;
  }

  .main-clain-on-head h2::before {
    border-width: 0 0 28px 16px;
  }

  .main-clain-on-head h2::after {
    border-width: 27px 19px 0 0;
  }
}

@media only screen and (max-width: 465px) {
  .main-clain-on-head h2,
  .main-clain-on-head .typed-effect {
    display: table;
  }

  .main-clain-on-head h2 {
    font-size: 1.5em;
    padding-top: 5px;
  }

  .main-clain-on-head h2::before {
    border-width: 0 0 68px 16px;
  }

  .main-clain-on-head h2::after {
    border-width: 68px 19px 0 0;
  }

  .main-clain-on-head h1 {
    font-size: 1.3em;
  }

  .main-clain-on-head h1 span {
    display: table;
  }

  .main-clain-on-head h1 span.intro-2b::after {
    border-width: 31px 14px 0 0;
    top: 27px;
  }
}

@media only screen and (max-width: 400px) {
  .main-clain-on-head {
    left: 20px;
  }

  .main-clain-on-head h2 {
    font-size: 1.3em;
  }

  .main-clain-on-head h2::before {
    border-width: 0 0 60px 16px;
  }

  .main-clain-on-head h2::after {
    border-width: 60px 19px 0 0;
  }
}

@media only screen and (max-width: 360px) {
  .main-clain-on-head h2 {
    font-size: 1.1em;
  }

  .main-clain-on-head h2::before {
    border-width: 0 0 54px 16px;
  }

  .main-clain-on-head h2::after {
    border-width: 54px 19px 0 0;
  }

  .main-clain-on-head h1 {
    font-size: 1.1em;
  }

  .main-clain-on-head h1 span.intro-2b::after {
    border-width: 29px 14px 0 0;
    top: 25px;
  }
}