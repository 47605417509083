/**
 ****************************************************************
 * (component) :: vertical TIMELINE  :: (upplication, 2016)
 ****************************************************************
 */

/* ... vertical TIMELINE :: Section :: Home / About Us views ... */

.timeline-section {
  border-top: 1px solid @header-border;
  padding-top: 2em;
  padding-bottom: 0;

  .row:last-child {
    margin-bottom: 0;
  }

  .timeline {
    position: relative;
    padding: 0;
    margin: 0;
    list-style: none;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 40px;
      width: 4px;
      margin-left: -1.5px;
      background-color: @bg-lilac-light;
      bottom: -100px;
    }

    // IMAGE Panel Card
    .timeline-image {
      z-index: 100;
      position: absolute;
      left: 0;
      width: 80px;
      height: 80px;
      margin-left: 0;
      border: 7px solid @bg-lilac-light;
      border-radius: 100%;
      text-align: center;
      color: @white;
      background-color: @white;
    }

    // TEXT Panel Cards
    .timeline-panel {
      float: right;
      position: relative;
      width: 100%;
      padding: 0 20px 0 100px;
      text-align: left;

      // to add the corner pointer
      &:after {
        right: auto;
        left: -14px;
        border-right-width: 14px;
        border-left-width: 0;
      }

      // Heading Title
      .timeline-heading {
        .subheading {
          text-transform: none;
          margin-bottom: 0;
          margin-top: 0;
          color: @dark-lilac;
          font-family: "Raleway", serif;
          font-size: 28px;
          font-weight: 500;
          padding: 15px 30px;
          background-color: @bg-lilac-light;
          text-align: center;

          // heading titles Numbers
          span {
            font-family: 'Lato', sans-serif;
            padding: 4px 11px;
            background-color: @white;
            border-radius: 50%;
            font-weight: 700;
            color: @medium-lilac;
            font-size: 20px;
            position: relative;
            bottom: 4px;
            margin-right: 10px;
            border: 1px solid @medium-lilac;
          }
        }
      }

      // Text Area
      .timeline-body {
        padding: 15px 30px;
        background-color: @header-border;
      }
      .timeline-body > ul,
      .timeline-body > p {
        margin-bottom: 0;
      }
      .timeline-body > p {
        font-size: 18px;
        padding-bottom: 12px;
        color: @dark-lilac;

        span {
          font-weight: 500;
        }

        b {
          font-weight: 600;
        }

        a {
          i {
            top: 1px;
            font-size: 14px;
            padding-left: 5px;
            -webkit-transition: padding 0.3s;
            -moz-transition: padding 0.3s;
            -ms-transition: padding 0.3s;
            -o-transition: padding 0.3s;
            transition: padding 0.3s;

            &:before {
              .rotate(180deg);
            }
          }

          &:hover {
            color: @black;
            i {
              padding-left: 20px;
            }
          }
        }
      }
    }
  }

  .timeline > li {
    position: relative;
    margin-bottom: 50px;
    min-height: 50px;

    // to add a clearfix
    &:before {
      content: " ";
      display: table;
    }

    &:after {
      content: " ";
      display: table;
      clear: both;
    }
  }
}

/* MEDIA QUERIES for TIMELINE Sections */

// Tablets & less for TIMELINE Sections
@media (max-width:767px) {
  .timeline-section {
    .timeline {
      .timeline-panel {
        // Heading Title
        .timeline-heading {
          &:after {
            width: 0;
            height: 0;
            border-top: 12px solid transparent;
            border-right: 12px solid @bg-lilac-light;
            border-bottom: 12px solid transparent;
            position: absolute;
            left: 89px;
            top: 20px;
            clear: both;
            float: none;
            content: ' ';
            display: block;
          }
        }
      }
    }
  }
}

// Smartphones
@media (max-width:580px) {
  .timeline-section {
    .timeline {
      .timeline-panel {
        .timeline-heading {
          .subheading {
            font-size: 22px;

            span {
              font-size: 18px;
              bottom: 2px;
            }
          }
        }
      }
    }
  }
}

@media (max-width:480px) {
  .timeline-section {
    .timeline {
      .timeline-panel {
        .timeline-heading {
          .subheading {
            font-size: 20px;
            text-align: left;
            padding: 15px;

            span {
              bottom: 0;
            }
          }
          .timeline-body > p {
            .font-mobile;
          }
        }
      }
    }
  }
}

// Big Screens Devises for TIMELINE Sections
@media (min-width:768px) {
  .timeline-section {
    .timeline {
      &:before {
        left: 50%;
        bottom: -100px;
      }

      .timeline-image {
        left: 50%;
        width: 100px;
        height: 100px;
        margin-left: -50px;
      }

      .timeline-panel {
        float: left;
        width: 41%;
        padding: 0 20px 20px 30px;
      }
    }

    .timeline > li {
      margin-bottom: 100px;
      min-height: 100px;

      &:not(.timeline-inverted) {
        .timeline-panel {
          &:after {
            width: 0;
            height: 0;
            border-top: 12px solid transparent;
            border-bottom: 12px solid transparent;
            border-right: none;
            border-left: 12px solid @bg-lilac-light;
            top: 32px;
            left: 94.6%;
            position: absolute;
            clear: both;
            float: none;
            content: ' ';
            display: block;
          }
        }
      }

      &.timeline-inverted {
        .timeline-panel {
          float: right;
          padding: 0 30px 20px 20px;
          text-align: left;

          &:before {
            width: 0;
            height: 0;
            border-top: 12px solid transparent;
            border-bottom: 12px solid transparent;
            border-right: 12px solid @bg-lilac-light;
            border-left: none;
            top: 32px;
            left: 2.2%;
            position: absolute;
            clear: both;
            float: none;
            content: ' ';
            display: block;
          }
        }
      }
    }
  }
}

@media (min-width:992px) {
  .timeline-section {
    .timeline {
      .timeline-panel {
        padding: 0 20px 20px;
      }

      .timeline-image {
        width: 150px;
        height: 150px;
        margin-left: -75px;
      }
    }

    .timeline > li {
      min-height: 150px;

      &.timeline-inverted {
        .timeline-panel {
          padding: 0 20px 20px;

          &:before {
            border-right: 12px solid @header-border;
            border-left: none;
            top: 65px;
            left: 1.7%;
          }
        }
      }

      &:not(.timeline-inverted) {
        .timeline-panel {
          &:after {
            border-right: none;
            border-left: 12px solid @header-border;
            top: 65px;
            left: 95.6%;
          }
        }
      }
    }
  }
}

@media (min-width:1200px) {
  .timeline-section {
    .timeline > li {
      min-height: 200px;

      .timeline-panel {
        padding: 15px;
        background-color: @header-border;
      }

      .timeline-image {
        width: 170px;
        height: 170px;
        margin-left: -85px;
      }

      &:not(.timeline-inverted) {
        .timeline-panel {
          &:after {
            top: 68px;
            border-right: none;
            border-left: 12px solid @header-border;
            left: 100%;
          }
        }
      }

      &.timeline-inverted {
        .timeline-panel {
          padding: 15px;
          background-color: @header-border;

          &:before {
            top: 68px;
            border-right: 12px solid @header-border;
            border-left: none;
            left: -2.3%;
          }
        }
      }
    }
  }
}