/**
 **********************************************************************
 * (component) :: TRIAL BLOCK :: (upplication, 2016)
 **********************************************************************
 */

/* ... "TRY IT FOR FREE 15 DAYS!" :: Footer Common in all pages ... */

.trial-block {
  background: @white;
  padding: 8em 0;

  // Text Side
  .trial-block-left {
    float: left;
    width: 70%;

    h2 {
      color: @medium-lilac;
      font-family: 'Lato', sans-serif;
      font-weight: 400;
      margin: 0;
      padding: 0;
      font-size: 2.58em;
    }

    h3 {
      color: @medium-lilac;
      font-size: 24px;
      margin-top: 10px;
    }

    span {
      color: @dark-lilac;
      padding: .5em 0 0 1em;
      display: block;
      margin: 0;
      font-weight: 500;

      i {
        top: -4px;
        font-size: 10px;
        right: 5px;
        opacity: .7;
      }
    }
  }

  // BTN side
  .trial-block-right {
    margin-top: 1.5em;
    float: right;
    text-align: right;
    width: 30%;

    a.price-btn {
      background: @accept-btn;
      color: @white;
      padding: 1.3em 1.6em;
      font-weight: 600;
      text-decoration: none;
      font-size: 20px;
      letter-spacing: 0.02em;

      &:hover {
        background: @accept-btn-hover;
      }

      i {
        top: 10px;
        right: 5px;
        font-size: 35px;
      }
    }
  }
}

/* ... TRIAL BLOCK :: media queries common ... */

// BIG SCREENS
@media only screen and (max-width: 1150px) {
  .trial-block {
    .trial-block-right {
      text-align: right;

      a.price-btn {
        padding: 1.3em 2.4em;
      }
    }
  }
}


@media (min-width: 981px) and (max-width: 1080px) {
  .trial-block {
    .trial-block-left {
      width: 60%;
    }
    .trial-block-right {
      width: 40%;
    }
  }
}


// MEDIUM SCREENS
@media (max-width: 980px) {
  // to center all elements in one block each
  .trial-block {
    padding: 7em 0;

    .trial-block-left {
      width: 100%;
      text-align: center;

      h2 {
        font-size: 2.5em;
      }

      span {
        padding: .8em 0 0 0;
      }
    }

    .trial-block-right {
      text-align: center;
      margin-top: 80px;
      width: 100%;
    }
  }
}


// TABLETS
@media (max-width:768px) {
  .trial-block {
    .trial-block-left {
      h2 {
        font-size: 2em;
      }
    }
    .trial-block-right {
      a.price-btn {
        padding: 1em 2em;
      }
    }
  }
}


// SMARTPHONE landscape Big
@media only screen and (max-width: 598px) {
  .trial-block {
    .trial-block-left {
      h2 {
        font-size: 1.8em;
      }
    }
  }
}


// SMARTPHONE landscape Small
@media only screen and (max-width: 480px) {
  .trial-block {
    .trial-block-left {
      span {
        font-size: .8em;
      }
    }
  }
}


// SMARTPHONE portrait
@media only screen and (max-width: 360px) {
  .trial-block {
    padding: 6em 0;

    .trial-block-right {
      a.price-btn {
        padding: 1em 1.5em;
      }
    }
  }
}