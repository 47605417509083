/**
 ***************************************************************
 ***************************************************************
 * {LESS} :: YOUTUBE PLAYER component :: (upplication, 2016)
 ***************************************************************
  **************************************************************
 */

.youtube-player {
  position: relative;
  padding-bottom: 56.23%;
  /* Use 75% for 4:3 videos */
  height: 0;
  overflow: hidden;
  max-width: 100%;

  .iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background: transparent;
  }

  .img {
    bottom: 0;
    display: block;
    left: 0;
    margin: auto;
    max-width: 100%;
    width: 100%;
    position: absolute;
    right: 0;
    top: 0;
    border: none;
    height: auto;
    cursor: pointer;
    -webkit-transition: .4s all;
    -moz-transition: .4s all;
    transition: .4s all;

    &:hover {
      -webkit-filter: brightness(75%);
    }
  }

  .play {
    height: 72px;
    width: 72px;
    left: 50%;
    top: 50%;
    margin-left: -36px;
    margin-top: -36px;
    position: absolute;
    background: url("@@config.base_path/images/common/youtube-player.png") no-repeat;
    cursor: pointer;
    opacity: .7;

    &:hover {
      opacity: 1;
    }
  }
}