/**
 ******************************************************
 ******************************************************
 * {LESS} :: COOKIES :: (upplication, 2016)
 ******************************************************
  *****************************************************
 */

@import (reference) "_common.less";

#cookies {
  display: none;
  position: fixed;
  bottom: 2%;
  width: 20%;
  left: 80%;
  z-index: 8999;
  background-color: rgba(0, 200, 107, .7);
  border-top: 1px solid @main-green;
  color: @black;
  font-weight: 400;
  font-size: 1em;
  padding: 12px 18px 0;

  &.show {
    display: block;
  }

  button {
    top: -4px;
    cursor: pointer;
    width: 60%;
    margin-bottom: 5px;
    .anim(all, 500ms, ease-in-out);

    &:hover {
      color: @main-green;
      text-decoration: underline;
    }
  }

  p {
    margin: 0 0 10px;
    position: relative;
    left: 10px;

    a {
      font-weight: 600;

      &:hover {
        color: @dark-green;
      }
    }
  }
}
.cookies-table{
  width: 70%;
}
.cookies-table tr:first-child{
  background-color: @main-green;
}
.cookies-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

// MEDIA QUERIES :: Cookies
@media only screen and (max-width: 900px) {
  #cookies {
    width:100%;
    left: 0;
  }
}