/**
 ******************************************************
 ******************************************************
 * {LESS} :: FOOTER :: (upplication, 2016)
 ******************************************************
  *****************************************************
 */

@import (reference) "_common.less";


/* ... CONTACT DATA :: PRE-FOOTER :: Footer Common in all pages... */

.customer-service {
  background-color: lighten(@medium-lilac, 10%);

  ul {
    margin: 0;
    -webkit-margin-before: 0em;
    -webkit-margin-after: 0em;

    li {
      padding: 20px 20px 15px 20px;
      display: inline-block;

      &:first-child {
        padding-left: 0;
      }

      &.tel {

        &:hover {
          background-color: lighten(@medium-lilac, 5%);
        }
      }

      &.mail {
        margin-left: -4px !important;

        &:hover {
          background-color: lighten(@medium-lilac, 5%);
        }
      }

      a, span {
        font-size: 18px;
        color: @dark-lilac;
        font-weight: 500;

        span {
          font-family: "Lato", sans-serif;
          font-weight: 600;
        }
      }
    }
  }
}


/* ... FOOTER :: Footer Common in all pages... */

footer.page-footer {
  padding-top: 20px;
  margin: 0;
  background-color: @light-lilac;

  /*
  // for Xmas BG decoration
  .container.padTop50.padBot50 {
    background-image: url("@@config.base_path/images/home/bg/footer-xmas-bg.png");
    background-repeat: no-repeat;
    background-position: bottom left;
  }
  // end of Xmas BG decoration
  */

  h6 {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    color: @dark-lilac;
    font-size: 15px;
  }

  .footLinks {
    li {
      padding: 5px 0;

      a {
        color: @darkest-lilac;
        font-size: 15px;
        letter-spacing: .03em;
        padding-left: 0;
        -webkit-transition: padding 0.5s;
        -moz-transition: padding 0.5s;
        -ms-transition: padding 0.5s;
        -o-transition: padding 0.5s;
        transition: padding 0.5s;

        &:hover {
          color: @black;
          padding-left: 20px;
        }
      }
    }
  }

  // Last bottom Footer :: Social Media
  .footer-copyright {
    font-weight: 400;
    background-color: @darkest-lilac;

    span {
      color: @main-green;
      /* to add padd between "copyright", Icon & current year (set by .js) */
      &:nth-child(2) {
        padding-left: 5px;
      }
      i {
        padding-right: 5px;
      }
    }

    i.icon-dot {
      font-size: 8px;
      padding: 0 10px;
      position: relative;
      top: 0;
      bottom: 1px;
      color: @white;
    }

    em,
    a {
      font-style: normal;
      font-size: 14px;
      color: @white;
    }

    a {
      padding-left: 8px;
      &:hover {
        text-decoration: underline;
      }
    }

    // Social Media external links linst
    ul.rrss.right {
      float: right;
      margin: 0;
      line-height: 50px;
      max-height: 50px;

      li {
        padding: 0 15px;
        display: inline-block;
        font-size: 10px;
        margin-right: -2px !important;
        margin-left: -2px !important;

        &:first-child {
          padding: 0 18px;
        }

        &.facebook:hover { background-color: @facebook; }
        &.twitter:hover { background-color: @twitter; }
        &.instagram:hover { background-color: @instagram; }
        &.vimeo:hover { background-color: @vimeo-alt; }
        &.youtube:hover { background-color: @youtube; }
        &.linkedin:hover { background-color: @linkedin; }
        &.github:hover { background-color: @github; }
        &.google {
          padding-right: 15px !important;
          line-height: 45px;

          &:hover { background-color: @google-plus; }

          a {
            font-size: 26px;
            position: relative;
            top: 4px;
          }
        }

        a {
          color: @white;
          font-size: 20px;
          text-align: center;

          i {
            text-align: center;
          }
        }
      }
    }
  }

  // Last bottom Footer :: Partners
  .footer-bottom {
    width: 100%;
    min-height: 150px;
    background-color: @bg-footer-lilac;

    ul {
      padding-top: 30px;
      text-decoration: none;
      text-align: center;

      li {
        display: inline-block;
        padding: 20px;
        padding-bottom: 10px;
        opacity: .5;

        &:last-child {
          padding-right: 0;
        }

        &:hover {
          opacity: 1;
        }

        &.fibonad {
          a img {
            width: 150px;
            height: 43px;
          }
        }
      }
    }
  }
}


/**
 ************************************************************
 * {LESS} :: MEDIA QUERIES :: FOOTER :: (upplication, 2016)
 ************************************************************
 */

/* ... CUSTOMERS SERVICE :: media queries common ... */

@media only screen and (max-width: 830px) {
  // to center all elements in one block each
  .customer-service {
    .container {
      padding-right: 0;
      padding-left: 0;

      ul {
        li {
          padding: 20px 20px 15px 20px;
          display: block;
          text-align: center;
        }
      }
    }
  }
}

/* ... SOCIAL MEDIA & VERY END FOOTER :: media queries common ... */

@media only screen and (max-width: 1280px) {
  .page-footer {
    .footer-copyright {
      .container {
        padding-right: 0;
      }
    }
  }
}


@media only screen and (max-width: 992px) {
  // to erase default "materialize" col values
  .page-footer {
    .footer-nav-col {
      width: 25% !important;
    }
  }
}


@media only screen and (max-width: 810px) {
  footer.page-footer {
    .footer-copyright {
      height: 100px;
      line-height: 30px;

      .container {
        padding-left: 0;
        padding-top: 10px;
        text-align: center;

        span {
          padding-left: 20px;
          margin-top: 20px;
        }

        ul {
          display: block;
          width: 100%;
          padding-top: 10px;
        }
      }
    }
  }
}


@media only screen and (max-width: 767px) {
  // to erase default "materialize" col values
  .page-footer {
    .footer-nav-col {
      width: 33% !important;
    }
  }
}


@media only screen and (max-width: 599px) {
  .page-footer {
    .padTop50 {
      padding-top: 10px;

      .footer-nav-col {
        width: 100% !important;
        padding-left: 30px;
      }
    }
    .footer-copyright {
      span {
        padding-left: 20px;
      }
    }
  }
}


@media only screen and (max-width: 540px) {
  footer.page-footer {
    .footer-bottom {
      ul {
        li {
          display: block;
          padding-right: 0;
        }
      }
    }
  }
}


@media only screen and (max-width: 499px) {
  .page-footer {
    .footer-copyright {
      font-size: 14px;

      span {
        font-size: 16px;
      }
    }
  }
}


@media only screen and (max-width: 450px) {
  .page-footer {
    .footer-copyright {
      font-size: 13px;

      span {
        font-size: 15px;
      }
    }
  }
}


@media only screen and (max-width: 420px) {
  footer.page-footer {
    .footer-copyright {
      height: 150px;
      line-height: 30px;

      span,
      em {
        display: block;
      }
      em {
        padding-left: 20px;
        font-size: 16px;
      }
      i.icon-dot {
        display: none;
      }
    }

    .footer-bottom {
      ul {
        li {
          display: block;
          padding-right: 0;
          padding-bottom: 10px;
        }
      }
    }
  }
}

