/**
 ******************************************************
 * (component) :: TABS :: (upplication, 2016)
 ******************************************************
 */

/* ... SAMPLES (showcases) :: "Apps for all types of business" Section :: Home / Ono / Telef�nica views ... */

#tabs {
  padding-top: 2em;
  padding-bottom: 2em;

  .plans-head {
    padding: 0 0 1em 0;
  }

  .plans-head > div {
    text-align: left;
  }

  img {
    width: 65%;
    height: auto;
    float: right;
  }

  .margTop50 {
    padding-left: 50px;
  }

  // each Tab navlink
  .tabs {
    line-height: 70px;
    overflow-x: hidden;

    // each Tab
    .tab {
      text-transform: inherit;

      a {
        color: @main-green;
        font-weight: 600;
        font-size: 25px;
        line-height: 25px;

        &:hover {
          color: @accept-btn-hover;
        }

        em {
          font-style: normal;
        }
      }
    }

    // each Tab underline
    .indicator {
      position: absolute;
      bottom: 8px;
      height: 2px;
      background-color: @main-green;
      will-change: left, right;

      &:after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        position: relative;
        border-right: 10px solid transparent;
        border-left: 10px solid transparent;
        border-top: 10px solid @main-green;
        bottom: 0;
        z-index: 10;
        will-change: left, right;
        left: 45%;
      }
    }
  }

  // Heading Titles of each Tab section
  h5 {
    color: @darkest-lilac;
    font-size: 26px;
    line-height: 40px;
    font-weight: 500;

    span {
      color: @main-green;
    }
  }

  // Content of each Tab
  .tabs-content-list {
    padding-bottom: 20px;

    li {
      a {
        color: lighten(@darkest-lilac, 5%);
        font-size: 20px;
        font-weight: 400;
        line-height: 42px;
        cursor: text;
        pointer-events: none;

        span {
          font-weight: 600;
        }

        i {
          color: @main-green;
          padding-right: 8px;
        }
      }
    }
  }

  // BTN to launch register modal dialog
  .btn-upp {
    background-color: @main-green;

    &:hover {
      background-color: @accept-btn-hover;
    }

    i {
      font-size: 40px;
    }
  }

  // deprecated - aplicateca
  .operating-systems {
    padding: 15px 0 20px 0;

    li {
      display: inline-block;

      i {
        color: #ddd;
        font-size: 4em;
        padding-right: 32px;
      }
    }
  }
}

/* ... Media Queries :: SHOWCASE :: Home view ... */

@media only screen and (max-width: 1150px) {
  #tabs .tabs .tab a  {
    font-size: 22px;
  }
}

@media only screen and (max-width: 1080px) {
  #tabs .tabs .tab a  {
    font-size: 20px;
  }
}

@media only screen and (max-width: 991px) {
  #tabs .tabs .tab a  {
    font-size: 18px;
  }

  #tabs .mobileImg {
    width: 30.33333%;

  }

  #tabs .mobileText {
    width: 61.33333%;
  }

  #tabs h5 {
    font-size: 24px;
    line-height: 32px;
  }
}

@media only screen and (max-width: 820px) {
  #tabs ul li.tab a span em {
    display: none;
  }

  #tabs .mobileImg {
    width: 28.33333%;
  }

  #tabs .mobileText {
    width: 63.33333%;
  }

  #tabs h5 {
    font-size: 20px;
    line-height: 30px;
  }
}

@media only screen and (max-width: 768px) {
  #tabs .mobileImg {
    width: 100%;
  }

  #tabs img {
    width: 50%;
    float: none;
    position: relative;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  #tabs .mobileText {
    width: 100%;
  }

  #tabs h5 {
    font-size: 20px;
    line-height: 30px;
  }

  #tabs div.margTop50 {
    padding-left: 20px;
  }
}

@media (min-width: 640px) and (max-width: 768px) {
  #tabs img {
    width: 40%;
  }
}

@media only screen and (max-width: 550px) {
  #tabs ul li.tab a span {
    font-size: 14px;
  }

  #tabs .tabs .indicator::after,
  .tabs .indicator::after {
    left: 40%;
  }
}

@media only screen and (min-width: 500px) {
  #tabs ul li.tab a span {
    display: block;
  }

  #tabs ul li.tab a i {
    display: none;
  }
}

@media only screen and (max-width: 599px) {
  // to show icons & hide text
  #tabs {
    .tabs {
      height: 70px;
      .tab {
        a {
          line-height: 50px;

          span {
            display: none;
          }
          i {
            display: block;
            font-size: 2.5em;
            font-weight: 400;
          }
        }
      }
    }
  }
}